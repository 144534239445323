
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/mixins.scss";
          @import "@/assets/scss/global/variables.scss";
          @import "@/assets/scss/global/variables2.scss";
          @import "@/assets/scss/global/variables3.scss";
          @import "@/assets/scss/global/keyframes.scss";
        
.air-datepicker-cell.-day-.-other-month-, .air-datepicker-cell.-year-.-other-decade- {
  color: var(--adp-color-other-month);
}

.air-datepicker-cell.-day-.-other-month-:hover, .air-datepicker-cell.-year-.-other-decade-:hover {
  color: var(--adp-color-other-month-hover);
}

.-disabled-.-focus-.air-datepicker-cell.-day-.-other-month-, .-disabled-.-focus-.air-datepicker-cell.-year-.-other-decade- {
  color: var(--adp-color-other-month);
}

.-selected-.air-datepicker-cell.-day-.-other-month-, .-selected-.air-datepicker-cell.-year-.-other-decade- {
  color: #fff;
  background: var(--adp-background-color-selected-other-month);
}

.-selected-.-focus-.air-datepicker-cell.-day-.-other-month-, .-selected-.-focus-.air-datepicker-cell.-year-.-other-decade- {
  background: var(--adp-background-color-selected-other-month-focused);
}

.-in-range-.air-datepicker-cell.-day-.-other-month-, .-in-range-.air-datepicker-cell.-year-.-other-decade- {
  background-color: var(--adp-background-color-in-range);
  color: var(--adp-color);
}

.-in-range-.-focus-.air-datepicker-cell.-day-.-other-month-, .-in-range-.-focus-.air-datepicker-cell.-year-.-other-decade- {
  background-color: var(--adp-background-color-in-range-focused);
}

.air-datepicker-cell.-day-.-other-month-:empty, .air-datepicker-cell.-year-.-other-decade-:empty {
  background: none;
  border: none;
}

.air-datepicker-cell {
  border-radius: var(--adp-cell-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.air-datepicker-cell.-focus- {
  background: var(--adp-background-color-hover);
}

.air-datepicker-cell.-current- {
  color: var(--adp-color-current-date);
}

.air-datepicker-cell.-current-.-focus- {
  color: var(--adp-color);
}

.air-datepicker-cell.-current-.-in-range- {
  color: var(--adp-color-current-date);
}

.air-datepicker-cell.-disabled- {
  cursor: default;
  color: var(--adp-color-disabled);
}

.air-datepicker-cell.-disabled-.-focus- {
  color: var(--adp-color-disabled);
}

.air-datepicker-cell.-disabled-.-in-range- {
  color: var(--adp-color-disabled-in-range);
}

.air-datepicker-cell.-disabled-.-current-.-focus- {
  color: var(--adp-color-disabled);
}

.air-datepicker-cell.-in-range- {
  background: var(--adp-cell-background-color-in-range);
  border-radius: 0;
}

.air-datepicker-cell.-in-range-:hover {
  background: var(--adp-cell-background-color-in-range-hover);
}

.air-datepicker-cell.-range-from- {
  border: 1px solid var(--adp-cell-border-color-in-range);
  background-color: var(--adp-cell-background-color-in-range);
  border-radius: var(--adp-cell-border-radius) 0 0 var(--adp-cell-border-radius);
}

.air-datepicker-cell.-range-to- {
  border: 1px solid var(--adp-cell-border-color-in-range);
  background-color: var(--adp-cell-background-color-in-range);
  border-radius: 0 var(--adp-cell-border-radius) var(--adp-cell-border-radius) 0;
}

.air-datepicker-cell.-range-to-.-range-from- {
  border-radius: var(--adp-cell-border-radius);
}

.air-datepicker-cell.-selected- {
  color: #fff;
  border: none;
  background: var(--adp-cell-background-color-selected);
}

.air-datepicker-cell.-selected-.-current- {
  color: #fff;
  background: var(--adp-cell-background-color-selected);
}

.air-datepicker-cell.-selected-.-focus- {
  background: var(--adp-cell-background-color-selected-hover);
}

.air-datepicker-body {
  transition: all var(--adp-transition-duration) var(--adp-transition-ease);
}

.air-datepicker-body.-hidden- {
  display: none;
}

.air-datepicker-body--day-names {
  display: grid;
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  margin: 8px 0 3px;
}

.air-datepicker-body--day-name {
  color: var(--adp-day-name-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.air-datepicker-body--cells {
  display: grid;
}

.air-datepicker-body--cells.-days- {
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  grid-auto-rows: var(--adp-day-cell-height);
}

.air-datepicker-body--cells.-months- {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: var(--adp-month-cell-height);
}

.air-datepicker-body--cells.-years- {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: var(--adp-year-cell-height);
}

.air-datepicker-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--adp-border-color-inner);
  min-height: var(--adp-nav-height);
  padding: var(--adp-padding);
  box-sizing: content-box;
}

.-only-timepicker- .air-datepicker-nav {
  display: none;
}

.air-datepicker-nav--title, .air-datepicker-nav--action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.air-datepicker-nav--action {
  width: var(--adp-nav-action-size);
  border-radius: var(--adp-border-radius);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.air-datepicker-nav--action:hover {
  background: var(--adp-background-color-hover);
}

.air-datepicker-nav--action:active {
  background: var(--adp-background-color-active);
}

.air-datepicker-nav--action.-disabled- {
  visibility: hidden;
}

.air-datepicker-nav--action svg {
  width: 32px;
  height: 32px;
}

.air-datepicker-nav--action path {
  fill: none;
  stroke: var(--adp-nav-arrow-color);
  stroke-width: 2px;
}

.air-datepicker-nav--title {
  border-radius: var(--adp-border-radius);
  padding: 0 8px;
}

.air-datepicker-nav--title i {
  font-style: normal;
  color: var(--adp-nav-color-secondary);
  margin-left: 0.3em;
}

.air-datepicker-nav--title:hover {
  background: var(--adp-background-color-hover);
}

.air-datepicker-nav--title:active {
  background: var(--adp-background-color-active);
}

.air-datepicker-nav--title.-disabled- {
  cursor: default;
  background: none;
}

.air-datepicker-buttons {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

.air-datepicker-button {
  display: inline-flex;
  color: var(--adp-btn-color);
  border-radius: var(--adp-btn-border-radius);
  cursor: pointer;
  height: var(--adp-btn-height);
  border: none;
  background: rgba(255, 255, 255, 0);
}

.air-datepicker-button:hover {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
}

.air-datepicker-button:focus {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
  outline: none;
}

.air-datepicker-button:active {
  background: var(--adp-btn-background-color-active);
}

.air-datepicker-button span {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.air-datepicker-time {
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 12px;
  align-items: center;
  position: relative;
  padding: 0 var(--adp-time-padding-inner);
}

.-only-timepicker- .air-datepicker-time {
  border-top: none;
}

.air-datepicker-time--current {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  text-align: center;
}

.air-datepicker-time--current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.air-datepicker-time--current-hours, .air-datepicker-time--current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.air-datepicker-time--current-hours:after, .air-datepicker-time--current-minutes:after {
  content: "";
  background: var(--adp-background-color-hover);
  border-radius: var(--adp-border-radius);
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}

.air-datepicker-time--current-hours.-focus-:after, .air-datepicker-time--current-minutes.-focus-:after {
  opacity: 1;
}

.air-datepicker-time--current-ampm {
  text-transform: uppercase;
  align-self: flex-end;
  color: var(--adp-time-day-period-color);
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.air-datepicker-time--row {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, var(--adp-time-track-color), var(--adp-time-track-color)) left 50%/100% var(--adp-time-track-height) no-repeat;
}

.air-datepicker-time--row:first-child {
  margin-bottom: 4px;
}

.air-datepicker-time--row input[type=range] {
  background: none;
  cursor: pointer;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.air-datepicker-time--row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.air-datepicker-time--row input[type=range]::-ms-tooltip {
  display: none;
}

.air-datepicker-time--row input[type=range]:hover::-webkit-slider-thumb {
  border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type=range]:hover::-moz-range-thumb {
  border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type=range]:hover::-ms-thumb {
  border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type=range]:focus {
  outline: none;
}

.air-datepicker-time--row input[type=range]:focus::-webkit-slider-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type=range]:focus::-moz-range-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type=range]:focus::-ms-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;
  -webkit-transition: background var(--adp-transition-duration);
  transition: background var(--adp-transition-duration);
}

.air-datepicker-time--row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;
  -moz-transition: background var(--adp-transition-duration);
  transition: background var(--adp-transition-duration);
}

.air-datepicker-time--row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;
  -ms-transition: background var(--adp-transition-duration);
  transition: background var(--adp-transition-duration);
}

.air-datepicker-time--row input[type=range]::-webkit-slider-thumb {
  margin-top: calc(var(--adp-time-thumb-size) / 2 * -1);
}

.air-datepicker-time--row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.air-datepicker-time--row input[type=range]::-moz-range-track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.air-datepicker-time--row input[type=range]::-ms-track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.air-datepicker-time--row input[type=range]::-ms-fill-lower {
  background: transparent;
}

.air-datepicker-time--row input[type=range]::-ms-fill-upper {
  background: transparent;
}

.air-datepicker {
  --adp-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --adp-font-size: 14px;
  --adp-width: 246px;
  --adp-z-index: 100;
  --adp-padding: 4px;
  --adp-grid-areas:
  "nav"
  "body"
  "timepicker"
  "buttons";
  --adp-transition-duration: .3s;
  --adp-transition-ease: ease-out;
  --adp-transition-offset: 8px;
  --adp-background-color: #fff;
  --adp-background-color-hover: #f0f0f0;
  --adp-background-color-active: #eaeaea;
  --adp-background-color-in-range: rgba(92, 196, 239, .1);
  --adp-background-color-in-range-focused: rgba(92, 196, 239, .2);
  --adp-background-color-selected-other-month-focused: #8ad5f4;
  --adp-background-color-selected-other-month: #a2ddf6;
  --adp-color: #4a4a4a;
  --adp-color-secondary: #9c9c9c;
  --adp-accent-color: #4eb5e6;
  --adp-color-current-date: var(--adp-accent-color);
  --adp-color-other-month: #dedede;
  --adp-color-disabled: #aeaeae;
  --adp-color-disabled-in-range: #939393;
  --adp-color-other-month-hover: #c5c5c5;
  --adp-border-color: #dbdbdb;
  --adp-border-color-inner: #efefef;
  --adp-border-radius: 4px;
  --adp-border-color-inline: #d7d7d7;
  --adp-nav-height: 32px;
  --adp-nav-arrow-color: var(--adp-color-secondary);
  --adp-nav-action-size: 32px;
  --adp-nav-color-secondary: var(--adp-color-secondary);
  --adp-day-name-color: #ff9a19;
  --adp-day-cell-width: 1fr;
  --adp-day-cell-height: 32px;
  --adp-month-cell-height: 42px;
  --adp-year-cell-height: 56px;
  --adp-pointer-size: 10px;
  --adp-poiner-border-radius: 2px;
  --adp-pointer-offset: 14px;
  --adp-cell-border-radius: 4px;
  --adp-cell-background-color-selected: #5cc4ef;
  --adp-cell-background-color-selected-hover: #45bced;
  --adp-cell-background-color-in-range: rgba(92, 196, 239, 0.1);
  --adp-cell-background-color-in-range-hover: rgba(92, 196, 239, 0.2);
  --adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);
  --adp-btn-height: 32px;
  --adp-btn-color: var(--adp-accent-color);
  --adp-btn-color-hover: var(--adp-color);
  --adp-btn-border-radius: var(--adp-border-radius);
  --adp-btn-background-color-hover: var(--adp-background-color-hover);
  --adp-btn-background-color-active: var(--adp-background-color-active);
  --adp-time-track-height: 1px;
  --adp-time-track-color: #dedede;
  --adp-time-track-color-hover: #b1b1b1;
  --adp-time-thumb-size: 12px;
  --adp-time-padding-inner: 10px;
  --adp-time-day-period-color: var(--adp-color-secondary);
  --adp-mobile-font-size: 16px;
  --adp-mobile-nav-height: 40px;
  --adp-mobile-width: 320px;
  --adp-mobile-day-cell-height: 38px;
  --adp-mobile-month-cell-height: 48px;
  --adp-mobile-year-cell-height: 64px;
}

.air-datepicker-overlay {
  --adp-overlay-background-color: rgba(0, 0, 0, .3);
  --adp-overlay-transition-duration: .3s;
  --adp-overlay-transition-ease: ease-out;
  --adp-overlay-z-index: 99;
}

.air-datepicker {
  background: var(--adp-background-color);
  border: 1px solid var(--adp-border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: var(--adp-border-radius);
  box-sizing: content-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, -webkit-max-content);
  grid-template-rows: repeat(4, max-content);
  grid-template-areas: var(--adp-grid-areas);
  font-family: var(--adp-font-family), sans-serif;
  font-size: var(--adp-font-size);
  color: var(--adp-color);
  width: var(--adp-width);
  position: absolute;
  transition: opacity var(--adp-transition-duration) var(--adp-transition-ease), transform var(--adp-transition-duration) var(--adp-transition-ease);
  z-index: var(--adp-z-index);
}

.air-datepicker:not(.-custom-position-) {
  opacity: 0;
}

.air-datepicker.-from-top- {
  transform: translateY(calc(var(--adp-transition-offset) * -1));
}

.air-datepicker.-from-right- {
  transform: translateX(var(--adp-transition-offset));
}

.air-datepicker.-from-bottom- {
  transform: translateY(var(--adp-transition-offset));
}

.air-datepicker.-from-left- {
  transform: translateX(calc(var(--adp-transition-offset) * -1));
}

.air-datepicker.-active-:not(.-custom-position-) {
  transform: translate(0, 0);
  opacity: 1;
}

.air-datepicker.-active-.-custom-position- {
  transition: none;
}

.air-datepicker.-inline- {
  border-color: var(--adp-border-color-inline);
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  transform: none;
}

.air-datepicker.-inline- .air-datepicker--pointer {
  display: none;
}

.air-datepicker.-is-mobile- {
  --adp-font-size: var(--adp-mobile-font-size);
  --adp-day-cell-height: var(--adp-mobile-day-cell-height);
  --adp-month-cell-height: var(--adp-mobile-month-cell-height);
  --adp-year-cell-height: var(--adp-mobile-year-cell-height);
  --adp-nav-height: var(--adp-mobile-nav-height);
  --adp-nav-action-size: var(--adp-mobile-nav-height);
  position: fixed;
  width: var(--adp-mobile-width);
  border: none;
}

.air-datepicker.-is-mobile- * {
  -webkit-tap-highlight-color: transparent;
}

.air-datepicker.-is-mobile- .air-datepicker--pointer {
  display: none;
}

.air-datepicker.-is-mobile-:not(.-custom-position-) {
  transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
}

.air-datepicker.-is-mobile-.-active-:not(.-custom-position-) {
  transform: translate(-50%, -50%);
}

.air-datepicker.-custom-position- {
  transition: none;
}

.air-datepicker-global-container {
  position: absolute;
  left: 0;
  top: 0;
}

.air-datepicker--pointer {
  --pointer-half-size: calc(var(--adp-pointer-size) / 2);
  position: absolute;
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  z-index: -1;
}

.air-datepicker--pointer:after {
  content: "";
  position: absolute;
  background: #fff;
  border-top: 1px solid var(--adp-border-color-inline);
  border-right: 1px solid var(--adp-border-color-inline);
  border-top-right-radius: var(--adp-poiner-border-radius);
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  box-sizing: border-box;
}

.-top-left- .air-datepicker--pointer, .-top-center- .air-datepicker--pointer, .-top-right- .air-datepicker--pointer, [data-popper-placement^=top] .air-datepicker--pointer {
  top: calc(100% - var(--pointer-half-size) + 1px);
}

.-top-left- .air-datepicker--pointer:after, .-top-center- .air-datepicker--pointer:after, .-top-right- .air-datepicker--pointer:after, [data-popper-placement^=top] .air-datepicker--pointer:after {
  transform: rotate(135deg);
}

.-right-top- .air-datepicker--pointer, .-right-center- .air-datepicker--pointer, .-right-bottom- .air-datepicker--pointer, [data-popper-placement^=right] .air-datepicker--pointer {
  right: calc(100% - var(--pointer-half-size) + 1px);
}

.-right-top- .air-datepicker--pointer:after, .-right-center- .air-datepicker--pointer:after, .-right-bottom- .air-datepicker--pointer:after, [data-popper-placement^=right] .air-datepicker--pointer:after {
  transform: rotate(225deg);
}

.-bottom-left- .air-datepicker--pointer, .-bottom-center- .air-datepicker--pointer, .-bottom-right- .air-datepicker--pointer, [data-popper-placement^=bottom] .air-datepicker--pointer {
  bottom: calc(100% - var(--pointer-half-size) + 1px);
}

.-bottom-left- .air-datepicker--pointer:after, .-bottom-center- .air-datepicker--pointer:after, .-bottom-right- .air-datepicker--pointer:after, [data-popper-placement^=bottom] .air-datepicker--pointer:after {
  transform: rotate(315deg);
}

.-left-top- .air-datepicker--pointer, .-left-center- .air-datepicker--pointer, .-left-bottom- .air-datepicker--pointer, [data-popper-placement^=left] .air-datepicker--pointer {
  left: calc(100% - var(--pointer-half-size) + 1px);
}

.-left-top- .air-datepicker--pointer:after, .-left-center- .air-datepicker--pointer:after, .-left-bottom- .air-datepicker--pointer:after, [data-popper-placement^=left] .air-datepicker--pointer:after {
  transform: rotate(45deg);
}

.-top-left- .air-datepicker--pointer, .-bottom-left- .air-datepicker--pointer {
  left: var(--adp-pointer-offset);
}

.-top-right- .air-datepicker--pointer, .-bottom-right- .air-datepicker--pointer {
  right: var(--adp-pointer-offset);
}

.-top-center- .air-datepicker--pointer, .-bottom-center- .air-datepicker--pointer {
  left: calc(50% - var(--adp-pointer-size) / 2);
}

.-left-top- .air-datepicker--pointer, .-right-top- .air-datepicker--pointer {
  top: var(--adp-pointer-offset);
}

.-left-bottom- .air-datepicker--pointer, .-right-bottom- .air-datepicker--pointer {
  bottom: var(--adp-pointer-offset);
}

.-left-center- .air-datepicker--pointer, .-right-center- .air-datepicker--pointer {
  top: calc(50% - var(--adp-pointer-size) / 2);
}

.air-datepicker--navigation {
  grid-area: nav;
}

.air-datepicker--content {
  box-sizing: content-box;
  padding: var(--adp-padding);
  grid-area: body;
}

.-only-timepicker- .air-datepicker--content {
  display: none;
}

.air-datepicker--time {
  grid-area: timepicker;
}

.air-datepicker--buttons {
  grid-area: buttons;
}

.air-datepicker--buttons, .air-datepicker--time {
  padding: var(--adp-padding);
  border-top: 1px solid var(--adp-border-color-inner);
}

.air-datepicker-overlay {
  position: fixed;
  background: var(--adp-overlay-background-color);
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), left 0s, height 0s, width 0s;
  transition-delay: 0s, var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration);
  z-index: var(--adp-overlay-z-index);
}

.air-datepicker-overlay.-active- {
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), height 0s, width 0s;
}

@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-ExtraBold.woff2") format("woff2"), url("/fonts/Manrope-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-Light.woff2") format("woff2"), url("/fonts/Manrope-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-Regular.woff2") format("woff2"), url("/fonts/Manrope-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: icons;
  font-display: swap;
  src: url("/fonts/icons.woff2") format("woff2"), url("/fonts/icons.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-SemiBold.woff2") format("woff2"), url("/fonts/Manrope-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-ExtraLight.woff2") format("woff2"), url("/fonts/Manrope-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-Bold.woff2") format("woff2"), url("/fonts/Manrope-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Manrope;
  font-display: swap;
  src: url("/fonts/Manrope-Medium.woff2") format("woff2"), url("/fonts/Manrope-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "icons";
  src: url("/fonts/icons.eot?1727788609");
  src: url("/fonts/icons.eot?1727788609#iefix") format("eot"), url("/fonts/icons.woff2?1727788609") format("woff2"), url("/fonts/icons.woff?1727788609") format("woff"), url("/fonts/icons.ttf?1727788609") format("truetype"), url("/fonts/icons.svg?1727788609#icons") format("svg");
}
.icon:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-archive-mail:before {
  content: "\e001";
}

.icon-archive:before {
  content: "\e002";
}

.icon-arrow-dropdown:before {
  content: "\e003";
}

.icon-arrow-left:before {
  content: "\e004";
}

.icon-arrow-right:before {
  content: "\e005";
}

.icon-attachment:before {
  content: "\e006";
}

.icon-bell:before {
  content: "\e007";
}

.icon-block:before {
  content: "\e008";
}

.icon-briefcase:before {
  content: "\e009";
}

.icon-burger:before {
  content: "\e00a";
}

.icon-calendar:before {
  content: "\e00b";
}

.icon-chain-end:before {
  content: "\e00c";
}

.icon-chain-middle:before {
  content: "\e00d";
}

.icon-chain-start:before {
  content: "\e00e";
}

.icon-check-blue:before {
  content: "\e00f";
}

.icon-check-double:before {
  content: "\e010";
}

.icon-check-total:before {
  content: "\e011";
}

.icon-check-white:before {
  content: "\e012";
}

.icon-clock:before {
  content: "\e013";
}

.icon-close:before {
  content: "\e014";
}

.icon-cloud:before {
  content: "\e015";
}

.icon-collapse:before {
  content: "\e016";
}

.icon-collector-mail:before {
  content: "\e017";
}

.icon-comment:before {
  content: "\e018";
}

.icon-contact-mail:before {
  content: "\e019";
}

.icon-contacts-add:before {
  content: "\e01a";
}

.icon-contacts-all:before {
  content: "\e01b";
}

.icon-contacts-fill:before {
  content: "\e01c";
}

.icon-contacts:before {
  content: "\e01d";
}

.icon-copy:before {
  content: "\e01e";
}

.icon-document:before {
  content: "\e01f";
}

.icon-domain:before {
  content: "\e020";
}

.icon-dots-2:before {
  content: "\e021";
}

.icon-dots:before {
  content: "\e022";
}

.icon-draft:before {
  content: "\e023";
}

.icon-edit:before {
  content: "\e024";
}

.icon-email-opened:before {
  content: "\e025";
}

.icon-email:before {
  content: "\e026";
}

.icon-enter:before {
  content: "\e027";
}

.icon-exit:before {
  content: "\e028";
}

.icon-export:before {
  content: "\e029";
}

.icon-eye-crossed:before {
  content: "\e02a";
}

.icon-eye:before {
  content: "\e02b";
}

.icon-favicon:before {
  content: "\e02c";
}

.icon-favorites-fill:before {
  content: "\e02d";
}

.icon-favorites-stroke:before {
  content: "\e02e";
}

.icon-filter-add:before {
  content: "\e02f";
}

.icon-filter:before {
  content: "\e030";
}

.icon-fire:before {
  content: "\e031";
}

.icon-folder-2-upload:before {
  content: "\e032";
}

.icon-folder-2:before {
  content: "\e033";
}

.icon-folder-add:before {
  content: "\e034";
}

.icon-folder-doc:before {
  content: "\e035";
}

.icon-folder-double:before {
  content: "\e036";
}

.icon-folder-fill:before {
  content: "\e037";
}

.icon-folder-special:before {
  content: "\e038";
}

.icon-folder:before {
  content: "\e039";
}

.icon-format-archive:before {
  content: "\e03a";
}

.icon-format-audio:before {
  content: "\e03b";
}

.icon-format-doc:before {
  content: "\e03c";
}

.icon-format-img:before {
  content: "\e03d";
}

.icon-format-other:before {
  content: "\e03e";
}

.icon-format-pdf:before {
  content: "\e03f";
}

.icon-format-video:before {
  content: "\e040";
}

.icon-format-xls:before {
  content: "\e041";
}

.icon-full:before {
  content: "\e042";
}

.icon-groups-add:before {
  content: "\e043";
}

.icon-groups:before {
  content: "\e044";
}

.icon-import:before {
  content: "\e045";
}

.icon-info:before {
  content: "\e046";
}

.icon-key:before {
  content: "\e047";
}

.icon-label-add:before {
  content: "\e048";
}

.icon-label-mask:before {
  content: "\e049";
}

.icon-label:before {
  content: "\e04a";
}

.icon-lang-english:before {
  content: "\e04b";
}

.icon-lang-portugal:before {
  content: "\e04c";
}

.icon-lang-russian:before {
  content: "\e04d";
}

.icon-lang-spanish:before {
  content: "\e04e";
}

.icon-lang-turkish:before {
  content: "\e04f";
}

.icon-line:before {
  content: "\e050";
}

.icon-link-connected:before {
  content: "\e051";
}

.icon-link-disconnected:before {
  content: "\e052";
}

.icon-lock-access:before {
  content: "\e053";
}

.icon-lock:before {
  content: "\e054";
}

.icon-logo-text:before {
  content: "\e055";
}

.icon-mail:before {
  content: "\e056";
}

.icon-mailbox:before {
  content: "\e057";
}

.icon-markers-1:before {
  content: "\e058";
}

.icon-markers-2:before {
  content: "\e059";
}

.icon-message-add:before {
  content: "\e05a";
}

.icon-message-opened:before {
  content: "\e05b";
}

.icon-message-special:before {
  content: "\e05c";
}

.icon-message:before {
  content: "\e05d";
}

.icon-minus:before {
  content: "\e05e";
}

.icon-new-message:before {
  content: "\e05f";
}

.icon-options-2:before {
  content: "\e060";
}

.icon-options:before {
  content: "\e061";
}

.icon-original:before {
  content: "\e062";
}

.icon-pdf-download:before {
  content: "\e063";
}

.icon-pdf:before {
  content: "\e064";
}

.icon-pen-add:before {
  content: "\e065";
}

.icon-pen-special:before {
  content: "\e066";
}

.icon-pen:before {
  content: "\e067";
}

.icon-plus:before {
  content: "\e068";
}

.icon-print:before {
  content: "\e069";
}

.icon-profile-new:before {
  content: "\e06a";
}

.icon-profile-special:before {
  content: "\e06b";
}

.icon-profiles-special:before {
  content: "\e06c";
}

.icon-question:before {
  content: "\e06d";
}

.icon-quotes:before {
  content: "\e06e";
}

.icon-refresh:before {
  content: "\e06f";
}

.icon-resend:before {
  content: "\e070";
}

.icon-save:before {
  content: "\e071";
}

.icon-scale:before {
  content: "\e072";
}

.icon-search:before {
  content: "\e073";
}

.icon-send-double:before {
  content: "\e074";
}

.icon-send:before {
  content: "\e075";
}

.icon-settings:before {
  content: "\e076";
}

.icon-shield:before {
  content: "\e077";
}

.icon-socials:before {
  content: "\e078";
}

.icon-spam:before {
  content: "\e079";
}

.icon-special:before {
  content: "\e07a";
}

.icon-success:before {
  content: "\e07b";
}

.icon-table:before {
  content: "\e07c";
}

.icon-text-1:before {
  content: "\e07d";
}

.icon-text-2:before {
  content: "\e07e";
}

.icon-text-3:before {
  content: "\e07f";
}

.icon-text-4:before {
  content: "\e080";
}

.icon-text-5:before {
  content: "\e081";
}

.icon-text-6:before {
  content: "\e082";
}

.icon-translate:before {
  content: "\e083";
}

.icon-trash-all:before {
  content: "\e084";
}

.icon-trash:before {
  content: "\e085";
}

.icon-ui:before {
  content: "\e086";
}

.icon-warning-message:before {
  content: "\e087";
}

.icon-warning:before {
  content: "\e088";
}

.icon-wm-logo:before {
  content: "\e089";
}

.icon-zoom-in:before {
  content: "\e08a";
}

.icon-zoom-out:before {
  content: "\e08b";
}

/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Set core body defaults.
 */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
  text-decoration: none;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/* Remove list styles, margin and padding on ul, ol elements, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main {
  background-color: #E5E7EB;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
}
.main__container {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 320px;
  height: calc(var(--vh, 1vh) * 100);
}
.main__container::-webkit-scrollbar {
  width: 0;
}
.main__container--welcome {
  display: flex;
  height: 100%;
}
.main__container--settings {
  height: auto;
  min-height: auto;
}
.page--welcome .main__container {
  height: 100vh;
}
.main__wrapper {
  position: sticky;
  background-color: white;
  bottom: -1px;
  padding: 20px;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.08);
  z-index: 2;
}
.main__container--preview + .main__wrapper {
  display: none;
}

@media (min-width: 768px) {
  .main {
    height: calc(var(--vh, 1vh) * 100 - 56px);
  }
  .main__container {
    display: flex;
    overflow: hidden;
    height: calc(var(--vh, 1vh) * 100 - 56px);
  }
  .main__container--settings {
    padding-bottom: 0;
  }
  .main__wrapper {
    display: none;
  }
}
@keyframes smooth-appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes smooth-disappearance {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes collapse {
  0% {
    width: 331px;
  }
  33% {
    width: 248px;
  }
  66% {
    width: 165px;
  }
  100% {
    width: 90px;
  }
}
@keyframes expand {
  0% {
    width: 90px;
  }
  33% {
    width: 165px;
  }
  66% {
    width: 248px;
  }
  100% {
    width: 331px;
  }
}
@keyframes appearance-animation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes disappearance-animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes showcopied {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(-50%);
  }
  70% {
    opacity: 1;
    transform: translateX(-10%) translateY(-50%);
  }
  100% {
    opacity: 0;
  }
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}

a:hover {
  color: inherit;
}

.dropdown-toggle::after {
  border: none;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

.offcanvas {
  position: absolute;
}

.offcanvas-backdrop {
  position: absolute;
  top: 56px;
  height: calc(100vh - 56px);
  z-index: 2;
}
.aside-panel .offcanvas-backdrop {
  top: 56px;
}

.tooltip-inner {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  color: #282B2E !important;
  font-family: "Manrope" !important;
  font-weight: 500 !important;
}

.tooltip-arrow {
  display: none !important;
}

@media (min-width: 768px) {
  .aside-panel .offcanvas-backdrop {
    top: 0;
  }
}
.checkbox-group__checkbox {
  cursor: pointer;
}
.checkbox-group__label {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  background-color: #EEEEEE;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.checkbox-group__label:active {
  background-color: #F0F8FF;
}
.checkbox-group__label:before {
  content: "";
  display: block;
  position: absolute;
  font-size: 24px;
  height: 24px;
  width: 24px;
}
.checkbox-group__input[checked=true] + .checkbox-group__label {
  background-color: #0080D8;
}
.checkbox-group__input[checked=true] + .checkbox-group__label:before {
  background: url("/images/icons/check-white.svg") no-repeat 50%;
  background-size: contain;
}
.checkbox-group__input {
  display: none;
}

@media (min-width: 1200px) {
  .checkbox-group__label:hover {
    background-color: #F0F8FF;
  }
}
.settings-content__fieldset .checkbox, .contacts-content__fieldset .checkbox {
  margin-top: 24px;
  width: 100%;
}
.content__fieldset .checkbox {
  margin-top: 24px;
  width: 100%;
}
.content__label + .checkbox {
  margin-top: 0;
}
.advanced-search .checkbox {
  width: 49%;
  margin-bottom: 8px;
}
.mails .checkbox {
  display: none;
}
.mail-mini--selected .checkbox {
  display: flex;
}
.mail-mini--selected .checkbox {
  display: flex;
}
.checkbox__input {
  display: none;
}
.checkbox__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  display: inline-block;
  position: relative;
  padding: 14px 48px;
  background-color: #F0F8FF;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  transition: color 0.15s ease-in-out;
}
.checkbox--bordered .checkbox__label {
  background: none;
  border: 2px solid #B8C0D0;
}
.checkbox--disabled .checkbox__label {
  pointer-events: none;
  opacity: 0.5;
}
.welcome__fieldset .checkbox__label {
  margin-top: 24px;
  background: none;
  padding: 0 0 0 32px;
}
.mail-mini .checkbox__label {
  background: none;
  padding: 0;
}
.advanced-search .checkbox__label {
  font-size: 13px;
  padding: 14px 12px 14px 48px;
}
.checkbox__label:hover, .checkbox__label:focus {
  outline: none;
}
.checkbox__label::after {
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #B8C0D0;
  background-color: #FFFFFF;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  transition: background-color 0.2s ease-in-out;
}
.welcome__fieldset .checkbox__label::after {
  left: 0;
}
.mail-mini .checkbox__label::after {
  border-color: #c6c9cf;
  position: static;
  transform: none;
  margin: auto;
}
.checkbox__input:checked ~ .checkbox__label::after {
  background: url("/images/icons/check-blue.svg") no-repeat 50%;
  background-size: 32px;
}
.mail-mini .checkbox__input:checked ~ .checkbox__label::after {
  background: url("/images/icons/check-white.svg") no-repeat 50%;
  background-color: #0080D8;
  background-size: 26px;
  border: 2px solid #0080D8;
}
.checkbox__label::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 8px;
}
.checkbox__input:checked + .checkbox__label::before {
  color: #0080D8;
  font-size: 30px;
}
.checkbox__input:checked + .checkbox__label {
  color: #0080D8;
}

@media (min-width: 576px) {
  .advanced-search .checkbox {
    width: 32%;
    margin-bottom: 0;
  }
  .advanced-search .checkbox__label {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .mails--slim .mail-mini__avatar-wrapper .checkbox, .mail-mini__avatar-wrapper:hover .checkbox {
    display: flex;
  }
  .mail-mini .checkbox__label {
    z-index: 1;
  }
  .mail-mini .checkbox__label::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .mail-mini__avatar-wrapper:hover .checkbox__label::after {
    background: url("/images/icons/check-blue.svg") no-repeat 50%;
    border: 2px solid #0080D8;
    background-size: 26px;
  }
  .mail-mini .mail-mini__avatar-wrapper:hover .checkbox__input[checked] + .checkbox__label::after {
    background: url("/images/icons/check-white.svg") no-repeat 50%;
    background-color: #0062a5;
    border: 2px solid #0062a5;
    background-size: 26px;
  }
}
.settings-content__creation .radio--group {
  margin-bottom: 24px;
}
.advanced-search .radio--group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
  background: none;
}
.radio__input {
  display: none;
}
.radio__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  display: inline-block;
  position: relative;
  padding: 16px 48px;
  background-color: #F0F8FF;
  border-radius: 8px;
  margin-top: 24px;
  cursor: pointer;
  user-select: none;
  width: 100%;
}
.radio__label:hover, .radio__label:focus {
  outline: none;
}
.radio__label::before, .radio__label::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 10px;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}
.radio__label::before {
  left: 15px;
  width: 24px;
  height: 24px;
  border: 2px solid #B8C0D0;
  background-color: #FFFFFF;
}
.advanced-search .radio__label::before {
  left: 0;
}
.radio__label::after {
  left: 21px;
  width: 12px;
  height: 12px;
}
.advanced-search .radio__label::after {
  left: 6px;
}
.radio__input:checked + .radio__label::after {
  background-color: #0080D8;
}
.radio__input:checked + .radio__label {
  color: #0080D8;
}
.advanced-search .radio__label {
  width: auto;
  white-space: nowrap;
  background: none;
  padding: 8px 32px;
}

@media (min-width: 576px) {
  .advanced-search .radio--group {
    flex-wrap: nowrap;
  }
}
.switcher {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 10px;
}
.switcher__checkbox {
  display: none;
}
.switcher__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  transition: 0.3s ease-in-out;
  border: 2px solid #0080D8;
  border-radius: 50px;
  cursor: pointer;
}
.switcher__slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: -2px;
  bottom: -2px;
  background-color: #0080D8;
  transition: 0.4s;
  border-radius: 50px;
}
.switcher__checkbox:checked + .switcher__slider:before {
  transform: translateX(12px);
}
.switcher__checkbox:checked + .switcher__slider {
  background-color: #FFFFFF;
}
.switcher__checkbox:focus + .switcher__slider {
  background-color: none;
}

.switcher--modified {
  width: 40px;
  height: 24px;
}
.switcher--modified .switcher__slider {
  border: 2px solid #D2D6DB;
  background-color: #E5E7EB;
}
.switcher--modified .switcher__slider::before {
  width: 24px;
  height: 24px;
  bottom: -2px;
  border: 2px solid #D2D6DB;
  background-color: #FFFFFF;
}
.switcher--modified .switcher__checkbox:checked + .switcher__slider {
  background-color: #66BB88;
  border: 2px solid #338665;
}
.switcher--modified .switcher__checkbox:checked + .switcher__slider::before {
  transform: translateX(18px);
}

.search-line {
  position: absolute;
  width: 32px;
  transition: width 0.3s ease-in-out;
  background-color: #FFFFFF;
}
.aside-panel .search-line {
  height: 55px;
  right: 56px;
  width: 32px;
}
.top-panel__item--search .search-line {
  top: 0;
  height: 48px;
}
.content__search-wrapper .search-line, .contacts-content__search-wrapper .search-line {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.top-panel .search-line--active {
  width: calc(46% - 60px);
}
.aside-panel .search-line--active {
  width: calc(100% - 100px);
  border-bottom: 3px solid #0080D8;
}
.content__search-wrapper .search-line--active, .contacts-content__search-wrapper .search-line--active {
  width: 100%;
  border-bottom: 3px solid #0080D8;
}
.top-panel__item:hover .search-line--active {
  background-color: #FFFFFF;
}
.search-line__wrapper {
  height: 100%;
}
.search-line__wrapper::before {
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 32px;
}
.aside-panel .search-line__wrapper::before {
  left: -3px;
}
.search-line__search {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #101828;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: 32px;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  height: 100%;
  padding: 0 0 0 32px;
  margin-left: 8px;
  border-radius: 0;
  border-top: 3px solid #FFFFFF;
  border-bottom: 3px solid #FFFFFF;
}
.search-line__search:focus {
  outline: none;
}
.search-line__search::placeholder {
  font-size: 12px;
}
.search-line--active .search-line__search {
  width: calc(100% - 101px);
}
.aside-panel .search-line--active .search-line__search {
  padding-left: 28px;
}
.content__search-wrapper .search-line--active .search-line__search, .contacts-content__search-wrapper .search-line--active .search-line__search {
  width: calc(100% - 75px);
}
.search-line__search::-webkit-search-cancel-button {
  display: none;
}
.search-line__advanced, .search-line__close {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: transparent;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  animation: smooth-appearance 0.4s ease-in-out forwards;
}
.search-line__advanced:focus, .search-line__close:focus {
  outline: none;
}
.search-line--active .search-line__advanced, .search-line--active .search-line__close {
  display: block;
}
.search-line__advanced:before, .search-line__close:before {
  display: block;
  color: #0080D8;
  font-size: 30px;
  line-height: 30px;
}
.search-line__advanced--active, .search-line__close--active {
  background-color: #0080D8;
}
.search-line__advanced--active:before, .search-line__close--active:before {
  color: #FFFFFF;
}
.search-line__advanced {
  right: 58px;
}
.search-line__close {
  background-color: #FFFFFF;
}
.search-line__close::before {
  display: none;
}

@media (min-width: 768px) {
  .top-panel__item--active .search-line::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 3px;
    height: 3px;
    width: 100%;
    background-color: #0080D8;
  }
  .aside-panel .search-line--active {
    width: calc(86% - 60px);
  }
  .content__search-wrapper .search-line--active, .contacts-content__search-wrapper .search-line--active {
    width: 90%;
  }
  .search-line__search::placeholder {
    font-size: 14px;
  }
  .top-panel .search-line__search::placeholder {
    font-size: 12px;
  }
  .search-line__advanced {
    right: 70px;
  }
  .search-line__close {
    right: -33px;
  }
  .search-line__close::before {
    display: block;
  }
  .content__line .search-line {
    display: block;
    height: 100%;
  }
  .aside-panel .search-line {
    display: none;
  }
}
@media (min-width: 1400px) {
  .top-panel .search-line--active {
    width: calc(60% - 60px);
  }
}
@media (min-width: 1566px) {
  .top-panel__item--active .search-line::before {
    left: 6px;
    width: calc(100% + 53px);
  }
  .top-panel__item--search .search-line__wrapper::before {
    padding-right: 44px;
  }
}
.search-container {
  width: 100%;
  position: relative;
}
.search-container__input {
  width: 100%;
}
.new-mail .search-container__input {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  padding: 2px;
  border: none;
  outline: none;
  height: 34px;
}
.contacts-content .search-container__input {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  font-style: normal;
  color: #0080D8;
  border-radius: 8px;
  border: 2px solid #B8C0D0;
  padding: 14px 12px;
  transition: 0.2s ease-in-out;
  outline: none;
}
.contacts-content .search-container__input:focus {
  border: 2px solid #0080D8;
  border-radius: 8px;
}
.contacts-content .search-container__input::placeholder {
  font-weight: 400;
  font-style: italic;
}
.search-container__list {
  position: absolute;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  width: max-content;
  max-width: 300px;
  max-height: 300px;
  overflow: auto;
  background-color: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}
.contacts-content .search-container__list {
  margin-left: 8px;
}
.search-container__list--active {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}
.search-container__list::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  background-color: #F0F8FF;
  border-radius: 2px;
  cursor: pointer;
}
.search-container__list::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 0 2px 2px 0;
}
.search-container__item {
  padding: 8px;
  cursor: pointer;
}
.search-container__item:hover {
  background-color: #F0F8FF;
}

.custom-select {
  position: relative;
  width: 100%;
}
.aside-panel .custom-select {
  white-space: nowrap;
  z-index: 4;
}
.aside-panel .custom-select, .aside-panel__offcanvas .custom-select {
  margin-bottom: 24px;
}
.aside-panel--collapsed .custom-select {
  margin-bottom: 16px;
}
.aside-panel--collapsed .aside-panel__offcanvas .custom-select {
  display: none;
}
.advanced-search .custom-select {
  margin: 0;
}
.settings-content__creation .custom-select, .contacts-content__creation .custom-select {
  margin-top: 8px;
}
.content__fieldset .custom-select {
  margin-top: 8px;
}
.custom-select__select {
  display: none;
}
.custom-select__selected {
  position: relative;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  background-color: #FFFFFF;
  color: #101828;
  border-radius: 8px;
  border: 2px solid #B8C0D0;
  cursor: pointer;
  padding: 14px;
}
.aside-panel--collapsed .custom-select__selected {
  padding: 9px;
}
.custom-select__selected:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 30px;
  line-height: 30px;
  transition: 0.15s ease-in-out;
}
.new-mail .custom-select__selected:before {
  right: 0;
}
.aside-panel .custom-select__selected, .aside-panel__offcanvas .custom-select__selected {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  border: none;
}
.new-mail .custom-select__selected {
  border-radius: 0;
  border: 0;
  color: #0080D8;
  padding: 8px 0;
}
.new-mail .custom-select__selected:before {
  color: #101828;
}
.advanced-search .custom-select__selected {
  color: #0080D8;
  box-shadow: none;
  border: 1px solid #B8C0D0;
}
.aside-panel__wrapper .custom-select__selected {
  font-size: 0;
  line-height: 0;
  width: calc(100% - 42px);
  margin: 0 auto;
  padding: 9px;
}
.aside-panel__wrapper .custom-select__selected::before {
  display: block;
  position: static;
  height: 30px;
  transform: translateY(-1px);
  transition: none;
}
.custom-select__selected--active::before {
  transform: translateY(-50%) rotate(-180deg);
}
.custom-select__text {
  display: block;
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.advanced-search .custom-select__text {
  width: auto;
}
.custom-select__menu {
  position: absolute;
  background-color: #FFFFFF;
  color: #101828;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  overflow: auto;
  max-height: 300px;
  transition: background-color 0.15s ease-in-out;
  width: 100%;
}
.custom-select__menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.custom-select__menu::-webkit-scrollbar-track {
  border-radius: 10px;
}
.custom-select__menu::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}
.custom-select__menu--hide {
  display: none;
}
.custom-select__item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 14px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  color: #101828;
}
.custom-select__item:hover {
  background-color: #F0F8FF;
}
.custom-select__item:active {
  background-color: #bde0ff;
}

@media (min-width: 576px) {
  .custom-select--small {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .aside-panel .custom-select {
    margin-bottom: 16px;
  }
  .aside-panel__offcanvas .custom-select {
    margin-bottom: 24px;
  }
  .aside-panel__wrapper .custom-select__menu {
    width: 250px;
  }
}
@media (min-width: 1200px) {
  .custom-select--small {
    width: 49%;
  }
  .aside-panel .custom-select {
    order: 2;
  }
  .aside-panel--collapsed .custom-select__selected {
    font-size: 0;
    line-height: 0;
    width: calc(100% - 42px);
    margin: 0 auto;
  }
  .aside-panel--collapsed .custom-select__selected::before {
    display: block;
    position: static;
    height: 30px;
    transform: translateY(-1px);
    transition: none;
  }
  .aside-panel--collapsed .custom-select__menu {
    width: 250px;
  }
  .aside-panel--collapsed .custom-select__text {
    margin: 0;
    width: 100%;
  }
  .aside-panel--collapsed .custom-select__selected .custom-select__text {
    display: none;
  }
}
.air-datepicker-nav {
  border-bottom: none;
}
.air-datepicker-nav--action svg path {
  stroke: #0080D8;
}
.air-datepicker-nav--title {
  color: #0080D8;
}
.air-datepicker-nav--title i {
  color: #0080D8;
}
.air-datepicker-body--day-name {
  color: #808086;
}
.air-datepicker-cell.-day- {
  color: #808086;
  font-weight: 500;
}
.air-datepicker-cell.-current- {
  color: #0080D8;
}
.air-datepicker-cell.-focus- {
  background-color: #F0F8FF;
  color: #0080D8;
}
.air-datepicker-cell.-selected- {
  color: #FFFFFF;
  background-color: #0080D8;
}
.air-datepicker-cell.-selected-.-focus- {
  background-color: #F0F8FF;
  color: #0080D8;
}

.datepicker__wrapper {
  margin-top: 8px;
  position: relative;
}
.datepicker__wrapper:before {
  position: absolute;
  top: 50%;
  left: 12px;
  content: "";
  background: url("/images/icons/calendar.svg") center center/contain no-repeat;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}
.datepicker__wrapper > input {
  padding-left: 38px;
  margin-top: 0;
}

.button {
  border-radius: 8px;
  width: 100%;
  white-space: normal;
  transition: color 0.1s, background-color 0.1s ease-in-out;
  border: none;
  user-select: none;
  background: none;
  padding: 14px;
  outline: none;
}
.button--primary {
  background-color: #0080D8;
  color: #FFFFFF;
}
.button--primary:active {
  background-color: #0062a5;
}
.popup .button--primary {
  margin-top: 24px;
  width: max-content;
}
.aside-panel .button--primary {
  display: none;
}
.aside-panel__offcanvas .button--primary {
  display: none;
}
.advanced-search .button--primary {
  margin: 0;
}
.settings-content__fieldset .button--primary:not(.button--impersonation):not(.settings-content__paragraph--code .button--primary):not(.settings-content__item .button--primary) {
  margin-top: 24px;
}
.content__fieldset .button--primary:not(.button--impersonation), .contacts-content__form .button--primary {
  margin-top: 24px;
}
.settings-content__paragraph--code .button--primary {
  width: initial;
  padding: 8px;
  margin-top: 8px;
}
.settings-content__item .button--primary {
  display: none;
}
.button--message {
  background-color: #66BB88;
}
.button--message:active {
  background-color: #49a56e;
}
.button--secondary {
  background-color: #F3F4F6;
  color: #0080D8;
}
.button--secondary:active {
  background-color: #F0F8FF;
}
.navigation .button--secondary {
  margin: 12px 0;
}
.settings-content__fieldset .button--secondary, .contacts-content__fieldset .button--secondary {
  margin-top: 24px;
}
.content__fieldset .button--secondary {
  margin-top: 24px;
}
.settings-content__container .button--secondary, .contacts-content__container .button--secondary {
  margin-top: 8px;
}
.welcome .button--secondary {
  margin-top: 24px;
}
.navigation .button--secondary {
  padding: 8px;
}
.inner .button--secondary {
  margin: 12px 0 0 0;
}
.search-line .button--secondary {
  display: none;
  width: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 6px;
  animation: smooth-appearance 0.4s ease-in-out forwards;
}
.search-line--active .button--secondary {
  display: block;
}
.new-mail .button--secondary {
  width: max-content;
  margin-left: 8px;
  padding: 4px;
}
.popup .button--secondary {
  margin-top: 24px;
  margin-left: auto;
}
.button--action {
  padding: 4px;
  width: max-content;
}
.button--action:active {
  background-color: #bde0ff;
}
.button--action:before {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}
.popup .button--action {
  position: absolute;
  right: -5px;
  top: 0;
}
.notification .button--action {
  margin-left: auto;
}
.contacts-content__contact .button--action {
  position: absolute;
  top: 10px;
  right: 42px;
}
.footer__block .button--action {
  margin-left: auto;
  width: 56px;
  height: 56px;
}
.settings-content__paragraph--code .button--action {
  position: relative;
  float: right;
  margin-left: 8px;
  margin-bottom: 8px;
  color: #D2D6DB;
}
.settings-content__paragraph--code .button--action:active {
  background: none;
  color: #384250;
}
.settings-content__element-block .button--action {
  margin-left: 8px;
}
.settings-content__element-block .button--action:first-of-type {
  margin-left: auto;
}
.mail-mini .button--action:hover {
  color: #0080D8;
}
.mail__line .button--action, .settings-content__element-block .button--action, .settings-content__item .button--action {
  position: relative;
}
.button--danger {
  background-color: #FEE7DA;
  color: #F54848;
}
.button--danger:active {
  color: #FFFFFF;
  background-color: #B0243C;
}
.settings-content__fieldset .button--danger {
  margin-top: 24px;
}
.content__fieldset .button--danger {
  margin-top: 24px;
}
.settings-content__fieldset--wide .button--danger {
  margin-right: 24px;
}
.content__fieldset--wide .button--danger {
  margin-right: 24px;
}
.contacts-content__form .button--danger {
  margin-top: 24px;
}
.contacts-content__contact .button--danger {
  position: absolute;
  top: 16px;
  right: 16px;
}
.contacts-content__contact .button--danger:active {
  background-color: #FEE7DA;
}
.popup .button--danger {
  margin-top: 24px;
}
.button--simple {
  background-color: transparent;
}
.button--simple:active {
  color: #0080D8;
  background-color: #F0F8FF;
}
.aside-panel .button--simple {
  padding: 5px 14px;
  margin-bottom: 10px;
  text-align: left;
}
.aside-panel .button--simple:active {
  background-color: #E6F2FB;
}
.button--small {
  width: max-content;
}
.settings-content__creation .button--small, .contacts-content__creation .button--small, .contacts-content__form .button--small {
  width: 48%;
}
.contacts-content__form > .button--small {
  width: max-content;
}
.content__creation .button--small, .content__form .button--small {
  width: 48%;
}
.content__form > .button--small {
  width: max-content;
}
.button--impersonation {
  right: 17px;
  top: 18px;
  margin-top: 24px;
}
.button--active {
  color: #0080D8;
  background-color: #E6F2FB;
}
.button__text {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  position: relative;
  padding-left: 30px;
}
.button__text:before {
  position: absolute;
  font-size: 30px;
  line-height: 30px;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
}
.aside-panel .button__text:before {
  left: 0;
}
.button__text--no-icon {
  padding: 0;
}
.search-line .button__text, .welcome .button__text, .settings-content__container .button__text, .contacts-content__container .button__text, .contacts-content__contacts > .button--secondary .button__text {
  padding: 0;
}
.welcome .button__text {
  font-size: 17px;
}
.main__wrapper .button__text {
  color: #FFFFFF;
}
.new-mail .button__text {
  font-size: 0;
  line-height: 0;
  padding-left: 0;
}
.new-mail .button__text::before {
  position: static;
  line-height: 32px;
}
.aside-panel .button--simple .button__text {
  margin: 0;
  padding: 0;
  height: auto;
}
.button__icon {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
}
.button--action .button__icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  line-height: 24px;
}
.mail__line .button--action .button__icon:before {
  font-size: 24px;
}
.mail-mini .button--action .button__icon:before {
  font-size: 24px;
}
.footer__block .button__icon {
  margin: auto;
}
.contacts-content__menu .button__icon {
  width: 30px;
  height: 30px;
}

@media (min-width: 400px) {
  .button--impersonation {
    position: absolute;
    width: max-content;
    right: 17px;
    top: 18px;
    margin: 0;
  }
  .new-mail__footer .button {
    padding: 12px;
    margin-left: 14px;
  }
  .new-mail .button__text {
    font-size: 12px;
    line-height: 20px;
    padding-left: 24px;
  }
  .new-mail .button__text::before {
    position: absolute;
  }
}
@media (min-width: 576px) {
  .new-mail__footer .button {
    padding: 14px;
  }
  .new-mail .button__text {
    font-size: 14px;
  }
  .top-panel .search-line .button--secondary {
    width: 64px;
  }
}
@media (min-width: 768px) {
  .aside-panel--collapsed .button--simple {
    display: none;
  }
  .aside-panel .button--primary {
    display: block;
    width: max-content;
    margin: 0 auto 24px auto;
    z-index: 4;
    padding: 9px;
  }
  .aside-panel__offcanvas .button--primary {
    width: 100%;
    margin-bottom: 24px;
    padding: 10px;
  }
  .encrypted .button--primary {
    width: max-content;
  }
  .settings-content__item .button--primary {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 104%;
    width: 172px;
  }
  .button--message {
    background-color: #66BB88;
  }
  .button--message:hover {
    background-color: #49a56e;
  }
  .search-line .button--secondary {
    font-size: 12px;
    padding: 6px;
  }
  .mail__actions .button--secondary {
    width: max-content;
  }
  .button--impersonation {
    right: 32px;
    top: 24px;
  }
  .inner .button__text {
    padding: 0;
  }
  .aside-panel .button__text {
    display: flex;
    align-items: center;
    font-size: 0;
    padding: 0;
    width: 30px;
    height: 28px;
    margin: 0 auto;
  }
  .aside-panel .button__text:before {
    position: static;
    transform: none;
  }
  .aside-panel__offcanvas .button__text {
    font-size: 14px;
    width: max-content;
  }
  .aside-panel__offcanvas .button__text:before {
    margin-right: 4px;
  }
}
@media (min-width: 1200px) {
  .aside-panel .button--simple {
    order: 2;
  }
  .aside-panel .button--simple:hover {
    background-color: #E6F2FB;
  }
  .aside-panel .button--simple:active {
    background-color: #E6F2FB;
  }
  .button--primary:hover {
    background-color: #0c9cff;
  }
  .button--primary:active {
    background-color: #0062a5;
  }
  .button--primary.button--message:hover {
    background-color: #78c396;
  }
  .button--primary.button--message:active {
    background-color: #49a56e;
  }
  .aside-panel .button--primary {
    min-height: 48px;
  }
  .aside-panel--collapsed .button--primary {
    transition: width 0s ease-in-out;
    width: calc(100% - 42px);
    padding: 9px;
  }
  .aside-panel__offcanvas .button--primary {
    display: block;
    order: 1;
    overflow: hidden;
    margin-bottom: 24px;
    min-height: 48px;
  }
  .button--secondary:hover {
    background-color: #F0F8FF;
  }
  .button--secondary:active {
    background-color: #bde0ff;
  }
  .button--danger:hover {
    color: #FFFFFF;
    background-color: #D23442;
  }
  .button--danger:active {
    color: #FFFFFF;
    background-color: #B0243C;
  }
  .button--simple:hover {
    background-color: #F0F8FF;
  }
  .button--simple:active {
    background-color: #bde0ff;
  }
  .aside-panel .button--simple:active {
    color: #0080D8;
  }
  .button--action:hover {
    background-color: #F0F8FF;
  }
  .button--action:active {
    background-color: #bde0ff;
  }
  .footer__block .button--action {
    display: none;
  }
  .settings-content__paragraph--code .button--action:hover {
    color: #384250;
    background: none;
  }
  .settings-content__paragraph--code .button--action:active {
    color: #384250;
    background: none;
  }
  .mail-mini .button--action {
    padding: 4px;
  }
  .new-mail--collapsed .button--action:hover {
    background: none;
  }
  .new-mail--collapsed .button__icon::before {
    transition: 0.2s ease-in-out;
  }
  .new-mail--collapsed .button__icon:hover::before {
    color: #99CCEF;
  }
  .button__text {
    width: max-content;
  }
  .aside-panel .button--primary .button__text {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .aside-panel .button--primary .button__text::before {
    position: static;
    transform: none;
    line-height: 20px;
  }
  .aside-panel .button__text {
    white-space: nowrap;
  }
  .aside-panel--collapsed .button__text {
    font-size: 0;
    line-height: 30px;
    width: 0;
  }
}
@media (min-width: 1300px) {
  .aside-panel > .button--primary {
    display: none;
  }
}
.input {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  font-style: normal;
  color: #0080D8;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #B8C0D0;
  margin-top: 8px;
  padding: 14px 12px;
  outline: none;
  transition: 0.2s ease-in-out;
}
.input--error {
  border: 2px solid #C43A3A;
  background-color: rgba(196, 58, 58, 0.4);
  color: #C43A3A;
}
.input--color {
  padding: 0;
  border-radius: 0;
  width: 48px;
  height: 48px;
}
.input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.radio--group ~ .input {
  margin-top: 24px;
}
.input::-ms-reveal {
  display: none;
}
.input:focus {
  border: 2px solid #0080D8;
  border-radius: 8px;
}
.input::placeholder {
  font-weight: 400;
  font-style: italic;
}
.input__label {
  padding: 12px 24px;
  background-color: #F3F4F6;
  color: #0080D8;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  transition: 0.2s ease-in-out;
  margin-top: 24px;
  cursor: pointer;
  user-select: none;
}
.input__label:active {
  background-color: #F0F8FF;
}
.input__text {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  position: relative;
  padding-left: 24px;
}
.input__text::before {
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
  font-size: 30px;
  line-height: 30px;
}

@media (min-width: 1200px) {
  .input__label:hover {
    background-color: #F0F8FF;
  }
  .input__label:active {
    background-color: #bde0ff;
  }
}
.link {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  color: #101828;
  padding: 14px;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  width: max-content;
}
.link:visited {
  color: #101828;
}
.link:active {
  color: #101828;
  background-color: #F0F8FF;
}
.aside-panel .link--back {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
}
.link__text {
  position: relative;
  padding-left: 32px;
}
.aside-panel .link__text {
  padding-left: 44px;
}
.link__text:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 30px;
  line-height: 30px;
}

.dropdown {
  width: 100%;
}
.dropdown--element {
  position: relative;
  width: max-content;
}
.settings-content__element-block .dropdown--element, .contacts-content__element-block .dropdown--element, .content__element-block .dropdown--element {
  margin-left: auto;
}
.top-panel .dropdown--element {
  display: flex;
}
.contacts-content__form .dropdown {
  margin-top: 24px;
}
.contacts-content__element-block .dropdown {
  margin-top: 0;
}
.mail .dropdown {
  width: max-content;
}
.mail__users .dropdown {
  margin-left: 8px;
}
.dropdown__button {
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: background-color 0.15s ease-in-out;
  border: none;
  outline: none;
  padding: 0;
}
.dropdown__button--secondary {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  width: 100%;
  color: #0080D8;
  background-color: #F3F4F6;
  padding: 14px;
  white-space: normal;
}
.dropdown__button--secondary:active {
  background-color: #bde0ff;
}
.mail .dropdown__button--secondary {
  padding: 8px;
}
.dropdown__button--element {
  padding: 4px;
  background-color: transparent;
}
.mail__item .dropdown__button--element:active {
  background-color: #bde0ff;
}
.top-panel__item--actions .dropdown__button--element {
  font-size: 0;
  padding: 0;
}
.dropdown__button:focus {
  outline: none;
}
.new-mail__footer .dropdown__button:active {
  background-color: #bde0ff;
}
.dropdown__menu {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
}
.dropdown--element .dropdown__menu {
  width: max-content;
}
.dropdown--secondary .dropdown__menu {
  width: 100%;
}
.mail .dropdown__menu {
  width: max-content;
  max-width: 320px;
}
.mail__users .dropdown__menu {
  width: max-content;
  max-width: 320px;
  width: 284px;
}
.dropdown__menu--active {
  display: block;
}
.dropdown__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  user-select: none;
  position: relative;
  padding: 8px 16px;
}
.dropdown .dropdown__item:active {
  background-color: #F0F8FF;
}
.dropdown__field {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 74px);
  margin-left: 4px;
}
.dropdown__icon {
  position: relative;
  display: block;
  height: 32px;
  width: 32px;
  transition: 0.15s ease-in-out;
  color: #384250;
}
.dropdown__icon::before {
  font-size: 32px;
  line-height: 32px;
  vertical-align: middle;
}
.dropdown__item .dropdown__icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dropdown__menu .dropdown__icon {
  height: 28px;
  width: 28px;
  margin-right: 8px;
  color: #384250;
}
.checkbox-group .dropdown__icon {
  height: 32px;
  width: 32px;
}
.top-panel__item--actions .dropdown__item .dropdown__icon, .mail__item--labels .dropdown__item .dropdown__icon {
  color: #FFFFFF;
  transition: 0.15s ease-in-out;
}
.top-panel__item--actions .dropdown__item:active .dropdown__icon, .mail__item--labels .dropdown__item:active .dropdown__icon {
  color: #F0F8FF;
}
.mail__item--folders .dropdown__item .dropdown__icon {
  color: #0080D8;
}
.top-panel__item--checkbox-group .show .dropdown__icon {
  transform: rotate(-180deg);
}
.mail__users .dropdown__icon {
  color: #0080D8;
  height: 20px;
  width: 20px;
}
.mail__users .dropdown__icon::before {
  font-size: 20px;
  line-height: 20px;
}
.dropdown__text {
  position: relative;
  padding-left: 24px;
}
.dropdown__text:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  font-size: 26px;
  line-height: 26px;
}
.mail .dropdown__text {
  padding-left: 0;
}
.mail__users .dropdown__text {
  margin: 0 4px;
}
.dropdown__heading {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  margin-right: 4px;
  width: 70px;
  text-align: left;
}
.dropdown__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mail .dropdown__name {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  width: calc(100% - 102px);
  flex-grow: 1;
  word-break: break-word;
  white-space: normal;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.mail__item .dropdown__name {
  max-width: 153px;
  width: auto;
}
.top-panel__item--actions .dropdown__name {
  max-width: 210px;
}

@media (min-width: 576px) {
  .dropdown__item--hide {
    display: none;
  }
}
@media (min-width: 768px) {
  .top-panel__item--actions .dropdown__button--element {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .dropdown__item:hover {
    background-color: #F0F8FF;
  }
  .dropdown__item:active {
    background-color: #bde0ff;
  }
  .new-mail__footer .dropdown__button:hover {
    background-color: #F0F8FF;
  }
  .new-mail__footer .dropdown__button:active {
    background-color: #bde0ff;
  }
  .dropdown__button--secondary:hover {
    background-color: #F0F8FF;
  }
  .mail__item .dropdown__button--element:hover {
    background-color: #F0F8FF;
  }
  .dropdown__name {
    margin-left: 4px;
  }
  .top-panel__item--actions .dropdown__item:hover .dropdown__icon, .mail__item--labels .dropdown__item:hover .dropdown__icon {
    color: #F0F8FF;
  }
}
@media (min-width: 1567px) {
  .top-panel__item--actions .dropdown__button--element {
    font-family: "Manrope";
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-style: normal;
    display: flex;
    align-items: center;
  }
}
.tag {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #E5E7EB;
  color: #0080D8;
  padding: 5px 5px 5px 8px;
  border-radius: 8px;
  z-index: 1;
  width: max-content;
}
.tag__icon {
  height: 24px;
}
.tag__icon::before {
  font-size: 24px;
  line-height: 24px;
}
.tag__name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag__close {
  height: 22px;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  margin-left: 4px;
}
.tag__close:before {
  font-size: 22px;
  line-height: 22px;
}
.tag__close:hover {
  background-color: #0080D8;
  color: #FFFFFF;
}
.tag-input {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  flex: 1 0 auto;
  height: 34px;
  outline: none;
  border: none;
  width: 100%;
}
.tag__field {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 2;
  width: 100%;
}

.custom-tooltip {
  position: absolute;
  left: 95px;
  background-color: #0080D8;
  color: #FFFFFF;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.custom-tooltip__text {
  display: block;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.webmoney-header {
  display: none;
}
.webmoney-header .n7g-iclgo {
  line-height: 32px;
}
.webmoney-header--welcome, .webmoney-header--encrypted {
  display: block;
  border-bottom: 1px solid #F3F4F6;
}

@media (min-width: 768px) {
  .webmoney-header {
    display: block;
  }
  .webmoney-header .n7g-adaptive .n7g-ctr {
    max-width: none !important;
    padding: 0 24px 0 16px;
  }
  .webmoney-header .n7g-icn:before {
    display: block;
  }
  .webmoney-header .j4k-SH_zX {
    width: calc(100% - 90px);
    margin-left: auto;
  }
  .webmoney-header .n7g-slvs {
    z-index: 1050;
  }
  .webmoney-header--welcome .j4k-SH_zX {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .webmoney-header .j4k-SH_zX {
    width: calc(100% - 331px);
  }
  .webmoney-header--welcome .j4k-SH_zX {
    width: 100%;
  }
  .webmoney-header .j4k-SOASn {
    left: 50%;
    transform: translateX(-50%);
  }
  .webmoney-header .n9g.n9g-adaptive .n9g-ctr {
    max-width: none;
    padding: 0 24px;
  }
}
@media (min-width: 768px) {
  .webmoney-footer--welcome {
    width: 100%;
  }
  .webmoney-footer .n7g-flv {
    box-shadow: none;
  }
  .webmoney-footer .n9g-ln {
    border-top: none;
  }
}
@media (min-width: 1200px) {
  .webmoney-footer {
    padding: 0 8px;
  }
  .webmoney-footer--welcome {
    width: 100%;
  }
}
.aside-panel {
  position: relative;
  background-color: #FFFFFF;
  z-index: 3;
}
.aside-panel__button {
  border: none;
  background: none;
  padding: 0;
  outline: none;
}
.aside-panel__button--open {
  width: 20px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  cursor: pointer;
  z-index: 7;
}
.aside-panel__button--collapse, .aside-panel__button--hide {
  display: none;
}
.aside-panel__button--open .aside-panel__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  width: 20px;
  background-color: #0080D8;
  border-radius: 20px;
  transition-duration: 0.25s;
  transition-delay: 0.25s;
}
.aside-panel__button--open .aside-panel__icon::before {
  content: "";
  left: 0;
  position: absolute;
  top: -6px;
  height: 2px;
  width: 20px;
  background-color: #0080D8;
  border-radius: 20px;
  transition-duration: 0.25s;
  transition: transform 0.25s, top 0.25s 0.25s;
}
.aside-panel__button--open .aside-panel__icon::after {
  content: "";
  left: 0;
  position: absolute;
  top: 6px;
  height: 2px;
  width: 20px;
  background-color: #0080D8;
  border-radius: 20px;
  transition-duration: 0.25s;
  transition: transform 0.25s, top 0.25s 0.25s;
}
.aside-panel__button--open.open .aside-panel__icon {
  transition-duration: 0.1s;
  transition-delay: 0.25s;
  background: transparent;
}
.aside-panel__button--open.open .aside-panel__icon::before {
  transition: top 0.25s, transform 0.25s 0.25s;
  top: 0px;
  transform: rotateZ(-45deg);
}
.aside-panel__button--open.open .aside-panel__icon::after {
  transition: top 0.4s, transform 0.25s 0.25s;
  top: 0px;
  transform: rotateZ(45deg);
}
.aside-panel__mailboxes .aside-panel__icon {
  width: 24px;
  height: 20px;
  color: #D2D6DB;
  transition: color 0.2s ease-in-out;
  margin-right: 8px;
}
.aside-panel__mailboxes .aside-panel__icon::before {
  font-size: 24px;
  line-height: 24px;
}
.aside-panel__item:active .aside-panel__icon, .aside-panel__item--active .aside-panel__icon::before {
  color: #0080D8;
}
.aside-panel__container {
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px 12px 12px 48px;
  border-bottom: 1px solid #EEEEEE;
  width: 100%;
  z-index: 5;
  height: 56px;
}
.aside-panel__wrapper {
  display: none;
}
.aside-panel__current-item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  transition-delay: 0.1s;
  width: calc(100% - 100px);
}
.aside-panel__menu-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 56px);
  overflow: hidden;
}
.aside-panel__counter {
  margin-left: 8px;
  border-radius: 8px;
  padding: 6px;
  max-width: 80px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.aside-panel__link {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  color: #101828;
  margin-left: auto;
  margin-right: 8px;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
}
.aside-panel__link::before {
  font-size: 32px;
  line-height: 31px;
  vertical-align: middle;
}
.aside-panel__link:active {
  background-color: #F0F8FF;
}
.aside-panel__offcanvas {
  position: fixed;
  top: 56px;
  box-shadow: 0px 4px 8px -3px rgba(34, 60, 80, 0.18) inset;
  border-right: 0;
  width: 83%;
  height: calc(var(--vh, 1vh) * 100 - 56px);
  min-width: 320px;
  padding: 24px 16px 0 16px;
  z-index: 1001;
}
.aside-panel__backdrop {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(var(--vh, 1vh) * 100 - 56px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.aside-panel__list {
  display: none;
}
.aside-panel__mailboxes {
  max-height: 282px;
  min-height: 100px;
  overflow: auto;
  margin-bottom: 24px;
}
.aside-panel--collapsed .aside-panel__mailboxes {
  display: none;
}
.aside-panel__mailboxes::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 2px;
}
.aside-panel__mailboxes::-webkit-scrollbar-track {
  border-radius: 10px;
}
.aside-panel__mailboxes::-webkit-scrollbar-thumb {
  background: #D2D6DB;
  border-radius: 2px;
}
.aside-panel__mailboxes .aside-panel__item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  width: calc(100% - 4px);
  padding: 4px 8px 4px 0;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s ease-in-out;
  border-radius: 8px;
}
.aside-panel__mailboxes .aside-panel__item:not(:last-of-type) {
  margin-bottom: 4px;
}
.aside-panel__mailboxes .aside-panel__item:active {
  color: #0080D8;
  background-color: #E6F2FB;
}
.aside-panel__item--active {
  color: #0080D8;
  background-color: #F0F8FF;
}
.aside-panel__mailboxes .aside-panel__text {
  display: block;
  max-width: calc(100% - 74px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aside-panel__mailboxes .aside-panel__text:first-of-type {
  max-width: none;
}
.aside-panel__mailboxes .aside-panel__counter {
  background-color: #E5E7EB;
  color: #6C737F;
  margin-left: auto;
  padding: 1px 8px;
}

@media (min-width: 768px) {
  .aside-panel {
    display: flex;
    flex-direction: column;
    padding: 24px 0 8px 0;
    z-index: 20;
    width: 90px;
    height: calc(var(--vh, 1vh) * 100 - 56px);
  }
  .aside-panel__offcanvas {
    top: 0;
    height: auto;
    max-width: 340px;
    padding: 19px 16px 16px 16px;
    border: none;
    outline: none;
  }
  .aside-panel__container {
    display: none;
  }
  .aside-panel__wrapper {
    display: block;
    position: relative;
    height: calc(100% - 120px);
    flex-grow: 1;
  }
  .aside-panel__wrapper::before, .aside-panel__wrapper::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 78%;
    height: 32px;
    z-index: 3;
  }
  .aside-panel__wrapper::before {
    top: 48px;
    background: linear-gradient(to bottom, rgb(255, 255, 255) 55%, transparent);
  }
  .aside-panel__wrapper::after {
    bottom: 13px;
    background: linear-gradient(to top, rgb(255, 255, 255) 45%, transparent);
  }
  .aside-panel--collapsed .aside-panel__wrapper::after {
    bottom: 14px;
  }
  .aside-panel__list {
    height: calc(100% - 84px);
    padding: 8px 0 14px 4px;
    margin: 20px 3px 0 4px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .aside-panel__list::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
  }
  .aside-panel__list::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  .aside-panel__list::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
  }
  .aside-panel__list:hover::-webkit-scrollbar-thumb {
    background-color: #0080D8;
  }
  .aside-panel__item {
    position: relative;
    margin-bottom: 16px;
    width: max-content;
    margin: 0 auto 16px auto;
  }
  .aside-panel__button {
    padding: 8px;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
  }
  .aside-panel__button--active {
    border: 1px solid #0080D8;
    color: #0080D8;
  }
  .aside-panel__button--open {
    position: static;
    transform: translate(0);
    width: 48px;
    min-height: 48px;
    transition: transform 0s;
    margin: 0 auto 24px auto;
  }
  .aside-panel__button--hide {
    font-family: "Manrope";
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-style: normal;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .aside-panel__button--hide::before {
    font-size: 30px;
    line-height: 30px;
    order: 2;
    margin-left: auto;
  }
  .aside-panel__button:active {
    background-color: #F0F8FF;
  }
  .aside-panel__list .aside-panel__icon {
    display: block;
    height: 30px;
    width: 30px;
    margin: 0 auto;
  }
  .aside-panel__list .aside-panel__icon::before {
    font-size: 30px;
    line-height: 30px;
  }
  .aside-panel__text {
    display: flex;
    align-items: center;
  }
  .aside-panel__text::before {
    font-size: 30px;
    line-height: 30px;
    margin-right: 8px;
  }
}
@media (min-width: 1200px) {
  .aside-panel__mailboxes {
    order: 2;
  }
  .aside-panel__mailboxes .aside-panel__item:hover {
    background-color: #E6F2FB;
  }
  .aside-panel__mailboxes .aside-panel__item:active {
    color: #0080D8;
  }
}
@media (min-width: 1300px) {
  .aside-panel {
    padding: 24px 16px 0 16px;
    border-bottom: 2px solid #f3f4f6;
    width: 331px;
    max-width: none;
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .aside-panel--collapsed {
    width: 90px;
    padding: 24px 0;
    transition: none;
  }
  .aside-panel__offcanvas {
    display: flex;
    flex-direction: column;
    position: static;
    width: 100%;
    height: 100%;
    min-width: auto;
    padding: 0;
    box-shadow: none;
    border: none;
  }
  .aside-panel--collapsed .aside-panel__offcanvas {
    height: auto;
  }
  .aside-panel__wrapper {
    display: none;
  }
  .aside-panel--collapsed .aside-panel__wrapper {
    display: block;
  }
  .aside-panel__list {
    display: none;
  }
  .aside-panel--collapsed .aside-panel__list {
    display: block;
  }
  .aside-panel__button--open {
    display: none;
  }
  .aside-panel__button--collapse {
    position: absolute;
    top: 37px;
    right: -16px;
    padding: 2px;
    display: block;
    font-size: 0;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  }
  .aside-panel__button--collapse::before {
    display: block;
    font-size: 20px;
    line-height: 20px;
  }
  .aside-panel--collapsed .aside-panel__button--collapse::before {
    transform: rotate(-180deg);
  }
  .aside-panel__button--hide {
    display: none;
  }
}
.navigation {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100 - 190px);
  overflow-y: auto;
  animation: smooth-appearance 0.7s ease-in-out forwards;
  padding: 0 8px;
}
.navigation::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.navigation::-webkit-scrollbar-track {
  border-radius: 10px;
}
.navigation::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}
.navigation__list {
  display: flex;
  flex-direction: column;
}
.navigation__item {
  position: relative;
}
.navigation__item:last-of-type {
  margin-top: auto;
}
.navigation__button {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #101828;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  user-select: none;
  border: 1px solid transparent;
}
.navigation__button:focus {
  outline: none;
}
.navigation__button--active {
  border: 1px solid #0080D8;
  color: #0080D8;
}
.navigation__button:active {
  background-color: #F0F8FF;
  border-radius: 8px;
}
.navigation__button:focus {
  outline: none;
}
.navigation__button:focus-within {
  background-color: transparent;
}
.navigation__add {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: transparent;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
  color: #0080D8;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 9px;
  margin-left: 8px;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.2s, background-color 0.2s ease-in-out;
  line-height: 30px;
  font-size: 30px;
  height: 30px;
  width: 30px;
  border-radius: 8px;
  margin-left: auto;
}
.navigation__add:focus {
  outline: none;
}
.navigation__add::before {
  color: #0080D8;
}
.navigation__item:hover .navigation__add {
  opacity: 1;
}
.navigation__item:hover .navigation__add:hover {
  background-color: #99CCEF;
}
.navigation__icon {
  margin-right: 3px;
  height: 30px;
}
.navigation__icon::before {
  display: block;
  font-size: 30px;
  line-height: 30px;
}
.navigation__icon:hover {
  background-color: #F0F8FF;
}
.navigation__name {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  max-width: calc(100% - 72px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.navigation__name--beta {
  position: relative;
}
.navigation__name--beta::before {
  content: "Beta";
  display: block;
  position: absolute;
  font-family: "Manrope";
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;
  top: 0;
  left: 105%;
  color: #BABCBF;
}
.navigation__counter {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  max-width: 160px;
  overflow: hidden;
  padding: 4px;
  color: #384250;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  margin-left: auto;
  min-width: 32px;
  max-width: 100px;
}
.navigation__button--active .navigation__counter {
  background-color: #0080D8;
  color: #FFFFFF;
}

@media (min-width: 1200px) {
  .navigation {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 288px);
    order: 3;
  }
  .navigation__button:hover {
    background-color: #F0F8FF;
  }
  .aside-panel--collapsed .navigation {
    display: none;
  }
}
.inner__list {
  width: 100%;
  padding: 4px 0 4px 32px;
}
.inner__item .inner__list {
  padding: 4px 0;
}
.inner__item, .inner__sub-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  cursor: pointer;
  position: relative;
}
.inner__item::before, .inner__sub-item::before {
  display: block;
  font-size: 30px;
  line-height: 30px;
}
.inner__item--label {
  display: flex;
  align-items: center;
  padding-left: 34px;
  margin-top: 8px;
}
.inner__item--label::before {
  color: #FFFFFF;
}
.inner__folder {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  border: 1px solid #ffffff;
  padding: 8px 14px;
  transition: 0.2s ease-in-out;
  width: 100%;
  color: #101828;
  border-radius: 8px;
}
.inner__folder::before {
  width: 30px;
  height: 30px;
  color: #101828;
  display: block;
  font-size: 30px;
  line-height: 30px;
  margin-right: 3px;
}
.inner__folder--active {
  color: #0080D8;
  border: 1px solid #0080D8;
  font-weight: 700;
}
.inner__folder--active::before {
  color: #0080D8;
}
.inner__folder:hover {
  color: #0080D8;
}
.inner__name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inner__item .inner__name {
  max-width: calc(100% - 80px);
}
.inner__sub-item .inner__name {
  max-width: calc(100% - 31px);
}
.inner__sub-item .inner__sub-item .inner__name {
  max-width: 100%;
}
.inner__counter {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  padding: 4px;
  border-radius: 8px;
  margin-left: auto;
}
.inner__folder--active .inner__counter {
  background-color: #0080D8;
  color: #FFFFFF;
}
.inner__folder-content {
  width: 100%;
}
.inner__sub-item {
  padding: 4px 0px 4px 32px;
}
.inner__sub-item .inner__sub-item {
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0 4px 32px;
}
.inner__sub-item .inner__sub-item::before {
  top: 18px;
  transform: translateY(-50%);
}
.inner__button {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  font-style: normal;
  position: relative;
  padding: 0;
}
.inner__button:focus {
  outline: none;
}
.inner__button::before {
  display: block;
  font-size: 30px;
  line-height: 30px;
}
.inner__button:hover {
  color: #0080D8;
}
.inner__button:hover::before {
  color: #101828;
}
.inner__button[aria-expanded=true]::before {
  transform: translateY(-50%) rotate(-180deg);
}
.inner__item--label .inner__button {
  max-width: calc(100% - 31px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inner__icon {
  display: block;
  height: 32px;
  width: 32px;
  margin-right: 3px;
  transition: 0.15s ease-in-out;
  transform: rotate(-180deg);
}
.inner__icon::before {
  color: #FFFFFF;
  font-size: 33px;
  line-height: 32px;
}
.inner__item--folder .inner__icon::before {
  color: #202840;
}
.inner__item--label .inner__icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
}
.inner__item--label .inner__icon::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 32px;
  width: 32px;
}
.inner__icon.collapsed {
  transform: rotate(0);
}

@media (min-width: 768px) {
  .inner__wrapper {
    position: relative;
  }
}
@media (min-width: 1200px) {
  .inner__wrapper:before {
    display: none;
  }
}
.folder-content {
  padding-left: 16px;
}
.folder-content__button {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  position: relative;
  padding: 0;
}
.folder-content__button:focus {
  outline: none;
}
.folder-content__content {
  margin-top: 8px;
}

.box {
  height: calc(var(--vh, 1vh) * 100 - 56px);
  display: flex;
  flex-direction: column;
}
.box__separator {
  width: 0.01% !important;
  margin: 0;
}

@media (min-width: 768px) {
  .box {
    width: calc(100% - 138px);
    padding: 24px 0;
    margin: 0 24px;
  }
  .box--split {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .box--encrypted {
    margin: auto;
  }
}
@media (min-width: 1200px) {
  .box {
    display: flex;
    width: calc(100% - 379px);
  }
  .box__separator {
    opacity: 0;
  }
  .box__separator:hover, .box__separator:active {
    opacity: 1;
  }
  .aside-panel + .box {
    flex: 1 1 0;
  }
  .aside-panel--collapsed + .box {
    width: calc(100% - 138px);
  }
}
@media (min-width: 1300px) {
  .box__separator {
    cursor: col-resize;
    width: 10px !important;
    order: 2;
    background-color: #D2D6DB;
    height: calc(var(--vh, 1vh) * 100 - 177px);
    margin: 0 8px;
    transition: 0.08s ease-in-out;
    border-radius: 8px;
  }
  .box__separator:active {
    opacity: 1;
  }
}
.top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  height: 48px;
  position: relative;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
  z-index: 2;
}
.top-panel--settings {
  display: none;
  padding: 8px 0;
  height: auto;
}
.top-panel--contacts {
  padding: 8px;
}
.top-panel__wrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}
.top-panel__wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.top-panel__list {
  display: flex;
  align-items: center;
}
.top-panel__list--left {
  width: max-content;
}
.top-panel--mail .top-panel__list--left, .top-panel--settings .top-panel__list--left, .top-panel--contacts .top-panel__list--left {
  width: min-content;
}
.box--split .top-panel__list--left {
  display: none;
}
.top-panel__list--right {
  margin-left: auto;
}
.top-panel--settings .top-panel__list--active, .top-panel--contacts .top-panel__list--active {
  display: flex;
}
.top-panel--encrypted .top-panel__list {
  width: 100%;
  justify-content: space-between;
}
.top-panel__item {
  user-select: none;
  transition: all 0.15s ease-in-out;
  animation: smooth-appearance 0.4s ease-in-out forwards;
  z-index: 1;
  border-radius: 8px;
}
.top-panel__item:not(.top-panel__item--search):not(.top-panel__item--actions) {
  padding: 4px;
}
.top-panel__item--search {
  display: none;
  padding-right: 4px;
}
.top-panel__item--search.top-panel__item--active {
  display: none;
}
.top-panel__item--actions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 72px;
  border-radius: 0;
  display: none;
  width: calc(100% - 73px);
  z-index: 7;
  background-color: #FFFFFF;
}
.top-panel__item--pages {
  cursor: auto;
}
.top-panel__item--active {
  display: block;
}
.top-panel__item--disabled {
  cursor: auto;
  opacity: 0.1;
  animation: none;
}
.top-panel__item:not(.top-panel__item--checkbox-group):not(.top-panel__item--actions):not(.top-panel__item--search):not(.top-panel__item--pages):not(.top-panel__item--links):not(.top-panel__item--total):active {
  background-color: #F0F8FF;
  border-radius: 8px;
}
.top-panel--settings .top-panel__item {
  transition: all 0.2s ease-in-out;
  overflow-x: hidden;
  cursor: pointer;
  margin-left: 12px;
  padding: 4px 8px;
}
.top-panel--mail .top-panel__item {
  margin-left: 8px;
}
.top-panel__item--actions .top-panel__item {
  margin-right: 8px;
}
.top-panel__button {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #101828;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0;
}
.top-panel__button:focus {
  outline: none;
}
.top-panel__button::before {
  font-size: 32px;
  line-height: 32px;
  vertical-align: middle;
  color: #384250;
}
.top-panel--settings .top-panel__button {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  font-style: normal;
}
.top-panel__name {
  display: none;
}
.top-panel__dropdown-menu {
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
}
.top-panel__dropdown-item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  font-style: normal;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.top-panel__dropdown-item:active {
  background-color: #F0F8FF;
}
.top-panel__link {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  display: block;
  color: #101828;
}
.top-panel__link::before {
  font-size: 32px;
  line-height: 31px;
  vertical-align: middle;
  margin-right: 4px;
}
.top-panel--mail .top-panel__link::before {
  margin-right: 0;
}
.top-panel__dropdown-menu .top-panel__link {
  padding: 8px 16px;
}
.top-panel--encrypted .top-panel__link {
  font-size: 0;
}
.top-panel__pages--current {
  margin-right: 5px;
}
.top-panel__pages--all {
  margin-left: 5px;
}

@media (min-width: 576px) {
  .top-panel__item--actions {
    width: max-content;
  }
  .top-panel--encrypted .top-panel__link {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .top-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 4px 0 4px 8px;
    margin-bottom: 24px;
    border-bottom: none;
    box-shadow: none;
  }
  .box--split .top-panel {
    width: 100%;
  }
  .top-panel--settings {
    display: flex;
  }
  .top-panel--mail {
    padding: 4px 8px;
  }
  .top-panel__wrapper {
    padding-left: 8px;
    width: 100%;
  }
  .top-panel__list {
    animation: smooth-appearance 0.4s ease-in-out forwards;
  }
  .top-panel__list--left {
    z-index: 3;
  }
  .top-panel__list--right:not(:last-child) {
    margin-right: 4px;
  }
  .top-panel--settings .top-panel__list--right, .top-panel--contacts .top-panel__list--right {
    display: block;
  }
  .top-panel--settings .top-panel__list--left {
    max-width: none;
  }
  .top-panel__item--checkbox-group {
    z-index: 9;
  }
  .top-panel__item--search {
    display: block;
    width: 38px;
    height: 38px;
    z-index: 8;
  }
  .top-panel__item--search.top-panel__item--active {
    display: block;
  }
  .top-panel__item--actions {
    position: static;
    height: auto;
    background: none;
    transform: none;
    width: auto;
    padding: 0;
    box-shadow: none;
  }
  .top-panel__item--links {
    padding: 0;
  }
  .top-panel__item--active {
    display: flex;
  }
  .top-panel__item--actions .top-panel__item {
    margin: 0;
  }
  .top-panel--settings .top-panel__item {
    margin-left: 0;
  }
  .top-panel__list--left .top-panel__item:not(:first-child) {
    margin-left: 4px;
  }
  .top-panel__list--right .top-panel__item {
    padding: 4px;
  }
  .top-panel__button {
    padding: 0;
    user-select: none;
  }
  .top-panel__item--disabled .top-panel__button {
    cursor: auto;
  }
  .top-panel__button--toggle {
    display: none;
  }
  .top-panel__dropdown-menu {
    display: flex;
    position: static;
    padding: 0;
    min-width: auto;
    box-shadow: none;
  }
  .top-panel__dropdown-item {
    padding: 4px;
    border-radius: 8px;
  }
  .top-panel__dropdown-item:not(:last-child) {
    margin-right: 4px;
  }
  .top-panel__link {
    font-size: 0;
  }
  .top-panel__link::before {
    margin-right: 0;
  }
  .top-panel__dropdown-menu .top-panel__link {
    padding: 0;
  }
  .top-panel--encrypted .top-panel__link {
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}
@media (min-width: 1200px) {
  .top-panel__item:not(.top-panel__item--checkbox-group):not(.top-panel__item--actions):not(.top-panel__item--pages):not(.top-panel__item--links):not(.top-panel__item--total):hover, .top-panel__dropdown-item:hover {
    background-color: #F0F8FF;
  }
  .box--split .top-panel__list--left {
    display: flex;
  }
  .top-panel__item {
    cursor: pointer;
  }
  .top-panel__list .top-panel__item:not(.top-panel__item--actions):not(.top-panel__item--links) {
    display: flex;
    align-items: center;
  }
  .top-panel__button {
    display: flex;
    align-items: center;
  }
  .top-panel__item--links .top-panel__button {
    display: none;
  }
}
@media (min-width: 1567px) {
  .top-panel__item--search {
    width: 97px;
  }
  .top-panel__name {
    font-family: "Manrope";
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-style: normal;
    display: block;
    margin-left: 4px;
  }
  .top-panel__item--search .top-panel__name {
    margin-left: 35px;
  }
}
.content {
  background-color: #FFFFFF;
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  /* &__more {
      height: 32px;
      width: 32px;
      margin-left: auto;
      border: none;
      outline: none;
      background: $transparent url("/images/icons/Dots.svg") no-repeat 50%;
      background-size: contain;
  } */
}
.content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.content__block {
  display: none;
}
.content__block--active {
  display: block;
}
.content__creation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content__container {
  width: 100%;
  margin-top: 24px;
}
.content__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content__fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.content__legend {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  margin-top: 24px;
  margin-bottom: 8px;
  display: block;
}
.content__title {
  font-family: "Manrope";
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 8px;
  width: 100%;
  text-transform: uppercase;
}
.content__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  display: block;
  color: #6C737F;
  margin-top: 24px;
  margin-bottom: 8px;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: min-content;
}
.content__label--small {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.content__list {
  width: 100%;
}
.content__list--decimal {
  padding-left: 16px;
  list-style-type: decimal;
  width: 100%;
}
.content__list--contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 2%;
  row-gap: 16px;
}
.content__item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 8px 0;
}
.content__list--decimal .content__item {
  font-weight: 400;
  padding-left: 8px;
}
.content__list--contacts .content__item {
  width: 100%;
  margin: 8px 0;
}
.content__link {
  color: #0080D8;
}
.content__element-block {
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  background-color: #F3F4F6;
  border-radius: 8px;
  padding: 24px;
}
.content__element-icon {
  position: relative;
  display: block;
  height: 28px;
  width: 28px;
}
.content__element-icon::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  line-height: 30px;
  color: #0080D8;
}
.content__element-block--label .content__element-icon:before {
  color: #F3F4F6;
}
.content__element-name {
  margin-left: 8px;
  color: #6C737F;
  max-width: calc(100% - 93px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content__editor {
  font-family: "Manrope";
  font-size: 24px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  display: flex;
  width: 100%;
  height: 140px;
  border-radius: 8px;
  background-color: #F0F8FF;
  margin-top: 32px;
}
.content__editor p {
  margin: auto;
}
.content__notification {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  max-width: 368px;
  margin-top: 24px;
}
.content__wrapper {
  width: 100%;
  margin-top: 24px;
  max-height: 470px;
  overflow-x: auto;
  overflow-y: auto;
  outline: 1px solid #EEEEEE;
  border-radius: 2px;
}
.content__wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.content__wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.content__wrapper::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}
.content__table {
  margin-bottom: 24px;
  position: relative;
}
.content__row {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 144%;
  font-weight: 500;
  font-style: normal;
  color: #101828;
}
.content__row--head {
  position: sticky;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #808086;
  background-color: #FFFFFF;
}
.content__row:nth-child(2n+1):not(:nth-child(1)) {
  background-color: #EEEEEE;
}
.content__row:nth-child(2n) {
  background-color: #F5F5F5;
}
.content__cell {
  padding: 24px 16px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.content__cell:first-child {
  text-align: center;
  max-width: 80px;
}
.content__cell:nth-child(2) {
  max-width: 140px;
}
.content__cell:nth-child(3) {
  max-width: 201px;
}
.content__cell:nth-child(4) {
  max-width: 160px;
}
.content__cell:last-child {
  width: 100%;
}
.content__paragraph {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #384250;
}
.content__paragraph--hint {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F8C020;
  background-color: #FEF7D2;
}
.content__paragraph--code {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  padding: 24px 16px;
  border-radius: 8px;
  border: 1px solid #D2D6DB;
  background-color: #F0F8FF;
  white-space: pre-line;
  word-break: break-all;
  width: 100%;
  overflow: auto;
}
.content__paragraph:first-of-type {
  margin-top: 24px;
}
.content__paragraph:not(:last-of-type) {
  margin-bottom: 16px;
}
.content__search-wrapper {
  position: relative;
  top: 0;
  width: 100%;
  height: 50px;
  margin-left: 4px;
}
.content__line {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.content__total {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  user-select: none;
}
.content__counter {
  margin-left: 8px;
}
.content__contacts {
  max-width: 368px;
  margin: 0 auto;
}
.content__textarea {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  padding: 10px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #B8C0D0;
  margin-top: 8px;
}

@media (min-width: 576px) {
  .content__fieldset--wide {
    max-width: none;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .content {
    border-radius: 8px;
    padding: 24px;
  }
  .content__container {
    max-width: 400px;
  }
  .content__list--contacts .content__item {
    width: 48%;
  }
  .content__list--active .content__item {
    cursor: pointer;
  }
  .content__info {
    width: 100%;
  }
  .content__form {
    flex-direction: column;
  }
  .content__creation, .content__fieldset {
    max-width: 400px;
  }
  .content__fieldset--wide {
    max-width: 430px;
  }
  .content__fieldset--extra-wide {
    max-width: none;
    width: 100%;
  }
  .content__button {
    max-width: 400px;
  }
  .content__table {
    width: 100%;
  }
  .content__contacts {
    max-width: none;
    margin: 0;
    margin-bottom: 16px;
  }
}
@media (min-width: 992px) {
  .content__list {
    justify-content: flex-start;
  }
  .content__list--contacts .content__item {
    width: 32%;
  }
  .content__list--active .content__item {
    cursor: pointer;
  }
}
@media (min-width: 1200px) {
  .content__contacts {
    max-width: 1700px;
  }
}
.advanced-search {
  display: none;
  position: absolute;
  top: 48px;
  left: 111px;
  z-index: 3;
}
.aside-panel .advanced-search {
  top: 56px;
  left: 0;
  width: 100%;
}
.advanced-search--active {
  display: block;
  animation: smooth-appearance 0.3s ease-in-out forwards;
}
.advanced-search__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}
.advanced-search__close {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: transparent;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
  position: absolute;
  padding: 4px;
  right: 8px;
  top: 8px;
}
.advanced-search__close:focus {
  outline: none;
}
.advanced-search__close:active {
  background-color: #F0F8FF;
}
.advanced-search__icon {
  display: block;
  height: 30px;
}
.advanced-search__icon::before {
  font-size: 30px;
  line-height: 30px;
  color: #0080D8;
}
.advanced-search__fieldset {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
}
.advanced-search__fieldset:last-of-type {
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.advanced-search__legend {
  display: block;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #808086;
  margin-top: 16px;
  margin-bottom: 8px;
}
.advanced-search__legend--hidden {
  display: none;
}
.advanced-search__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #808086;
  margin-top: 16px;
  width: 100%;
}
.advanced-search__input {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 12px 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  border: 2px solid #B8C0D0;
  color: #0080D8;
  width: 100%;
  margin-top: 8px;
}
.advanced-search__input:focus {
  outline: 2px solid #0080D8;
}
.advanced-search__input::placeholder {
  font-weight: 400;
  font-style: italic;
}
.advanced-search__input--start-date, .advanced-search__input--end-date {
  padding-left: 44px;
}
.advanced-search__input--start-date::-webkit-calendar-picker-indicator, .advanced-search__input--end-date::-webkit-calendar-picker-indicator {
  display: none;
}
.advanced-search__input--checkbox {
  display: none;
}

@media (min-width: 576px) {
  .aside-panel .advanced-search {
    width: calc(95% - 48px);
    left: 46px;
  }
  .aside-panel .advanced-search__container {
    border-radius: 8px;
  }
}
@media (min-width: 768px) {
  .advanced-search {
    width: calc(80% - 278px);
    min-width: 500px;
  }
  .advanced-search__container {
    border-radius: 8px;
  }
  .advanced-search__label {
    width: 49%;
  }
}
@media (min-width: 1200px) {
  .advanced-search {
    max-width: 1000px;
  }
}
.attach {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: column wrap;
  height: 75px;
  border-radius: 4px;
  width: 100%;
  background-color: #E5E7EB;
  position: relative;
}
.attach__preview {
  position: relative;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  width: 75px;
  height: 75px;
}
.attach__preview_hidden {
  width: 0;
}
.attach__image {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #D2D6DB;
}
.attach__image::before {
  font-size: 48px;
  margin: auto;
}
.attach__row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 0;
}
.attach__row:not(:nth-last-of-type(3)) {
  padding: 0px 8px;
  border-radius: 0 4px 0 0;
  overflow: hidden;
}
.attach__preview ~ .attach__row {
  width: calc(100% - 75px);
}
.attach__row:last-child {
  margin-bottom: 8px;
}
.attach__progress {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  width: 100%;
  height: 5px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.attach--success .attach__progress {
  background-color: #44BB88;
}
.attach--error .attach__progress {
  background-color: #F54848;
}
.attach__preview ~ .attach__row .attach__progress {
  border-radius: 0 8px 0 0;
}
.attach__name {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  color: #384250;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.attach__format {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 2px 12px;
  border-radius: 8px;
  background-color: #384250;
}
.attach__error {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  width: calc(100% - 100px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #F54848;
  cursor: default;
}
.attach__error-text:focus {
  display: block;
  position: absolute;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
}
.attach__weight {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 400;
  font-style: normal;
  color: #384250;
  margin-left: auto;
  margin-right: 9px;
}

@media (min-width: 576px) {
  .attach {
    width: 49%;
  }
}
@media (min-width: 992px) {
  .attach {
    max-width: 32%;
  }
  .box--split .attach {
    max-width: none;
  }
}
.notification {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  background-color: #FFFFFF;
  padding: 8px;
}
body > .notification {
  width: calc(100vw - 40px);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  border-radius: 8px;
  z-index: 1050;
  margin: 0 auto;
  min-width: 280px;
}
.mails > .notification {
  font-family: "Manrope";
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  position: absolute;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  max-width: none;
  margin: 0;
  border-bottom: 1px solid #E5E7EB;
  background-color: #F0F8FF;
  padding: 8px 8px 8px 40px;
  z-index: 1;
  overflow: hidden;
  transform: translateY(-100%);
  border-radius: 8px 8px 0 0;
}
.mails > .notification.appearance {
  animation: appearance-animation 0.5s ease-in-out forwards;
}
.mails > .notification.disappearance {
  animation: disappearance-animation 0.5s ease-in-out forwards;
}
.mails > .notification.sticky {
  position: sticky;
}
.mails > .notification::before {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}
.notification_error .notification__icon {
  display: none;
}
.notification_error .notification__icon_error {
  display: block;
}
.notification_error .notification__timer-bar {
  background-color: #F54848;
}
.notification_error .notification__title {
  color: #F54848;
}
.notification__value, .notification__action {
  font-weight: 700;
  margin-right: 4px;
}
.notification__action {
  color: #0080D8;
  border: none;
  background: none;
  outline: none;
  padding: 0;
}
.notification__timer {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #D2D6DB;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
}
.notification__timer-bar {
  width: 25%;
  height: 4px;
  border-radius: 2px;
  background-color: #66BB88;
}
.notification__row {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 0;
  margin-bottom: 8px;
}
.notification__icon {
  flex-shrink: 0;
}
.mails > .notification .notification__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
.mails > .notification .notification__icon:before {
  font-size: 24px;
  line-height: 25px;
  vertical-align: middle;
}
body > .notification .notification__icon:before {
  font-size: 30px;
  line-height: 30px;
  vertical-align: middle;
}
.notification__icon_error {
  display: none;
  color: #F54848;
}
.notification__close {
  margin-left: auto;
  flex-shrink: 0;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification__close:hover {
  background-color: #F0F8FF;
}
.notification__title {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  color: #384250;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification__text:not(.mails .notification__text) {
  padding-left: 44px;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  color: #384250;
}
.mails > .notification .notification__text {
  padding-left: 0;
  margin-right: 4px;
}

@media (min-width: 768px) {
  body > .notification {
    top: auto;
    left: auto;
    transform: none;
    right: 24px;
    bottom: 24px;
    max-width: 400px;
  }
  .box--split .notification {
    flex-wrap: wrap;
  }
  .mails > .notification {
    font-size: 14px;
  }
  .notification__action:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
@media (min-width: 1200px) {
  body > .notification {
    right: 48px;
    bottom: 48px;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(32, 40, 64, 0.64);
  z-index: 21;
}
.popup::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.mail .popup {
  position: absolute;
  background: none;
}
.popup__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 90%;
  max-height: calc(100% - 32px);
  background-color: #FFFFFF;
  z-index: 3;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 16px;
}
.popup__container--label {
  overflow-y: auto;
}
.popup__container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.mail .popup__container {
  top: 30px;
  transform: translateX(-50%) translateY(0);
}
.popup__form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.popup__title {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 144%;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 24px;
  width: 100%;
}
.popup__text {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  width: 100%;
  word-break: break-word;
}
.popup__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 400;
  font-style: normal;
  color: #808086;
  margin-bottom: 8px;
  width: 100%;
}
.popup__label:not(:first-of-type) {
  margin-top: 24px;
}
.popup__error {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  color: #C43A3A;
  font-style: italic;
}

@media (min-width: 400px) {
  .popup__container {
    min-width: 80%;
  }
}
@media (min-width: 768px) {
  .mail .popup {
    position: absolute;
    background: none;
  }
  .popup__container {
    min-width: auto;
    width: 330px;
  }
}
.user {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-flow: column wrap;
  height: 48px;
  row-gap: 8px;
}
.aside-panel .user {
  margin-left: auto;
  column-gap: 0;
  row-gap: 0;
}
.aside-panel--settings .user, .search-container .user {
  margin-left: 0;
}
.user-menu .user {
  column-gap: 20px;
  margin-left: 0;
}
.new-mail__dropdown--type-container .user {
  margin-left: 0;
}
.dropdown__field .user {
  align-items: center;
  flex-flow: row nowrap;
  height: auto;
  margin-left: 0;
  width: 100%;
}
.mail__users .user {
  width: calc(100% - 8px);
}
.dropdown__item .user {
  width: 100%;
}
.aside-panel__container .user__avatar-wrapper {
  margin: auto;
}
.mail__users .user__avatar-wrapper, .new-mail__item .user__avatar-wrapper {
  margin-right: 8px;
}
.user__letter {
  font-family: "Manrope";
  font-size: 24px;
  line-height: 14px;
  font-weight: 700;
  font-style: normal;
  color: #FFFFFF;
}
.aside-panel .user__letter, .mail .user__letter {
  font-size: 16px;
}
.user__nickname, .user__email {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s ease-in-out;
  color: #101828;
}
.aside-panel .user__nickname, .aside-panel .user__email {
  display: none;
}
.user-menu .user__nickname, .user-menu .user__email {
  position: relative;
  display: block;
  width: calc(100% - 48px);
}
.mail__users .user__nickname, .mail__users .user__email {
  max-width: calc(100% - 115px);
}
.new-mail__dropdown .user__nickname, .new-mail__dropdown .user__email {
  max-width: calc(100% - 56px);
  margin: 0;
}
.dropdown .user__nickname, .dropdown .user__email {
  color: #101828;
  max-width: calc(100% - 40px);
}
.user__nickname {
  color: #101828;
}
.user__email {
  color: #0080D8;
}
.user__email:hover {
  color: #0080D8;
}
.dropdown .user__email:hover {
  color: #101828;
}
.user__wmid {
  position: relative;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #6C737F;
}
.user__wmid:active, .user__wmid:hover {
  color: #6C737F;
}
.user__label {
  margin-right: 4px;
}
.user__icon {
  line-height: 16px;
  font-size: 16px;
  height: 16px;
  margin-left: 4px;
}

@media (min-width: 576px) {
  .mail__users > .user__nickname, .mail__users > .user__email {
    max-width: 255px;
  }
}
.user-menu {
  position: absolute;
  background-color: #ffffff;
  right: 0;
  top: 100%;
  border-radius: 8px;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  padding: 16px 0;
  opacity: 0;
  transition: 0.3s ease;
  transform: translateY(-200%);
  pointer-events: none;
}
.user-menu--active {
  transform: translateY(0);
  pointer-events: auto;
  opacity: 1;
}
.user-menu__item {
  display: flex;
  align-items: center;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  padding: 8px 24px;
  transition: 0.2s ease-in-out;
}
.user-menu__item:not(:first-child) {
  margin-top: 8px;
}
.user-menu__item:not(:first-child):active {
  background-color: #F0F8FF;
}
.user-menu__item:last-child {
  position: relative;
}
.user-menu__item:last-child::before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  width: calc(100% - 48px);
  height: 1px;
  background-color: #F3F4F6;
}
.user-menu__icon {
  font-size: 32px;
  line-height: 32px;
  height: 32px;
  margin: 0 24px 0 4px;
}

.preloader {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fbfbfb;
  z-index: 10000;
}
.preloader .spinner-border {
  margin: auto;
  width: 3rem;
  height: 3rem;
  color: #0080D8;
}
.preloader__icon {
  display: block;
  user-select: none;
  pointer-events: none;
}
.preloader__icon:first-child {
  color: #036bb3;
  margin: 32px auto 0 auto;
}
.preloader__icon:first-child::before {
  font-size: 56px;
  line-height: 56px;
}
.preloader__icon:last-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.encrypted {
  background-color: #FFFFFF;
  padding: 16px;
  height: calc(var(--vh, 1vh) * 100 - 104px);
}
.encrypted__title {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 8px;
}
.encrypted__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  color: #6C737F;
}
.encrypted__label:nth-of-type(1) {
  max-width: 400px;
}
.encrypted__label:nth-of-type(2) {
  height: calc(var(--vh, 1vh) * 100 - 386px);
  margin-bottom: 24px;
}
.encrypted__body {
  margin-top: 24px;
  border-radius: 8px;
  padding: 14px;
  background-color: #F0F8FF;
  height: calc(var(--vh, 1vh) * 100 - 217px);
  overflow: auto;
  margin-top: 24px;
  scrollbar-width: 5px;
  scrollbar-color: #0080D8;
}
.encrypted__body::-webkit-scrollbar {
  width: 8px;
  background-color: #F0F8FF;
  border-radius: 0;
  cursor: pointer;
}
.encrypted__body::-webkit-scrollbar-track {
  border-radius: 0;
}
.encrypted__body::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 0;
}
.encrypted__input {
  margin-top: 8px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #D2D6DB;
  outline: none;
  transition: 0.2s ease-in-out;
  height: 40px;
}
.encrypted__input:focus {
  border: 1px solid #0080D8;
  color: #0080D8;
}
.encrypted__area {
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid #D2D6DB;
  padding: 16px;
  outline: none;
  height: 100%;
  resize: none;
}
.encrypted__area:focus {
  border: 1px solid #0080D8;
}

@media (min-width: 768px) {
  .encrypted {
    height: calc(var(--vh, 1vh) * 100 - 175px);
    border-radius: 8px;
  }
  .encrypted__label:nth-of-type(2) {
    height: calc(var(--vh, 1vh) * 100 - 448px);
    margin-bottom: 24px;
  }
  .encrypted__body {
    height: calc(var(--vh, 1vh) * 100 - 286px);
  }
}
.footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  order: 4;
  animation: smooth-appearance 0.7s ease-in-out forwards;
}
.aside-panel--collapsed .footer {
  display: none;
}
.footer__list {
  position: absolute;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  bottom: 38px;
  left: 16px;
  border-radius: 8px;
  display: none;
  background-color: #FFFFFF;
}
.footer__button--active + .footer__list {
  display: block;
}
.footer__item, .footer__button {
  color: #BABCBF;
  font-family: "Manrope";
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 8px 8px 8px 40px;
  transition: 0.2s ease-in-out;
}
.footer__item--russian, .footer__item--english, .footer__item--spanish, .footer__item--portuguese, .footer__item--turkish, .footer__button--russian, .footer__button--english, .footer__button--spanish, .footer__button--portuguese, .footer__button--turkish {
  position: relative;
}
.footer__item--russian::before, .footer__item--english::before, .footer__item--spanish::before, .footer__item--portuguese::before, .footer__item--turkish::before, .footer__button--russian::before, .footer__button--english::before, .footer__button--spanish::before, .footer__button--portuguese::before, .footer__button--turkish::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
.footer__item--russian::before, .footer__button--russian::before {
  background: url("/images/icons/lang-russian.svg") no-repeat 50%;
  background-size: cover;
}
.footer__item--english::before, .footer__button--english::before {
  background: url("/images/icons/lang-english.svg") no-repeat 50%;
  background-size: cover;
}
.footer__item--spanish::before, .footer__button--spanish::before {
  background: url("/images/icons/lang-spanish.svg") no-repeat 50%;
  background-size: cover;
}
.footer__item--portuguese::before, .footer__button--portuguese::before {
  background: url("/images/icons/lang-portugal.svg") no-repeat 50%;
  background-size: cover;
}
.footer__item--turkish::before, .footer__button--turkish::before {
  background: url("/images/icons/lang-turkish.svg") no-repeat 50%;
  background-size: cover;
}
.footer__item {
  cursor: pointer;
  font-family: "Manrope";
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  color: #4D5761;
}
.footer__item:not(:last-child) {
  border-bottom: 1px solid #D2D6DB;
}
.footer__item:active {
  background-color: #f3f4f6;
}
.footer__button {
  background: none;
  border: none;
  padding: 8px 8px 8px 40px;
}
.footer__button .footer__name {
  position: relative;
}
.footer__button .footer__name::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -24px;
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  transition: 0.2s ease-in-out;
}
.footer__button--active .footer__name::before {
  transition: 0.2s ease-in-out;
  transform: translateY(-50%) rotate(-180deg);
}
.footer__copyright, .footer__link {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  color: #BABCBF;
  margin-left: auto;
  white-space: nowrap;
  overflow-x: hidden;
}
.footer__link {
  width: 100%;
  text-align: right;
  transition: 0.2s ease-in-out;
  padding-top: 5px;
}

@media (min-width: 1200px) {
  .footer {
    margin-top: auto;
  }
  .footer__block {
    top: auto;
    bottom: 103px;
    left: 100px;
    width: auto;
    height: 254px;
  }
  .footer__item:hover {
    background-color: #f3f4f6;
  }
}
.pagination {
  display: flex;
  align-items: center;
  margin: auto auto 0 auto;
  width: max-content;
}
.pagination__quantity {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
}
.pagination__counter--previous {
  margin-right: 8px;
}
.pagination__counter--next {
  margin-left: 8px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #C4C4C4;
  width: 40px;
  height: 40px;
}
.mail-mini .avatar {
  margin-top: 1px;
  height: 100%;
  width: 100%;
}
.avatar__letter {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  color: #FFFFFF;
}
.mail-mini--selected .avatar {
  display: none;
}

@media (min-width: 992px) {
  .mails--slim .mail-mini__avatar-wrapper .avatar, .mail-mini__avatar-wrapper:hover .avatar {
    display: none;
  }
}
.welcome {
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
}
.welcome__container {
  padding: 24px;
  margin: auto;
  width: 90%;
}
.welcome__header {
  display: flex;
  justify-content: space-between;
}
.welcome__title {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 144%;
  font-weight: 500;
  font-style: normal;
  margin: 0;
}
.welcome__fieldset {
  position: relative;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 24px;
}
.welcome__fieldset::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
  background-color: #EEEEEE;
}
.welcome__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 400;
  font-style: normal;
  color: #808086;
  margin-top: 24px;
  width: 100%;
}
.welcome__label--checkbox {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
}
.welcome__label--checkbox::before, .welcome__label--checkbox::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 10px;
  border-radius: 8px;
  transition: 0.1s ease-in-out;
}
.welcome__label--checkbox::before {
  border: 2px solid #808086;
}
.welcome__label--checkbox:hover, .welcome__label--checkbox:focus {
  outline: none;
}
.welcome__checkbox:checked ~ .welcome__label:not(.welcome__label--terms) {
  color: #0080D8;
}
.welcome__input {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
}
.welcome__input:focus {
  color: #0080D8;
}
.welcome__input::placeholder {
  font-weight: 400;
  font-style: italic;
}
.welcome__checkbox {
  display: none;
}
.welcome__button {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 144%;
  font-weight: 500;
  font-style: normal;
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #0080D8;
  background-color: #F0F8FF;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: 100%;
  margin-top: 24px;
}
.welcome__button:focus {
  outline: none;
}
.welcome__button:hover {
  background-color: #bde0ff;
}
.welcome__button--colored {
  background-color: #0080D8;
  color: #FFFFFF;
}
.welcome__button--colored:hover {
  background-color: #0c9cff;
}
.welcome__link {
  color: #0080D8;
  margin-left: 3px;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .welcome {
    width: 721px;
    height: calc(100% - 32px);
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: auto;
    box-shadow: 10px 14px 30px 0px rgba(34, 60, 80, 0.12);
    border-radius: 8px;
  }
  .welcome::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .welcome__container {
    position: relative;
    margin: 0 0 0 auto;
    height: 500px;
    max-width: 480px;
    border-left: 1px solid #EEEEEE;
  }
  .welcome__container::before {
    content: "WebMoney Mail";
    display: block;
    position: absolute;
    font-family: "Manrope";
    font-size: 22px;
    line-height: 144%;
    font-weight: 500;
    font-style: normal;
    color: #0080D8;
    width: max-content;
    height: 136px;
    background: url("/images/icons/mail.svg") no-repeat 50% 58px;
    top: 24px;
    left: -200px;
  }
}
.mails {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background-color: #E5E7EB;
  scrollbar-width: none;
  transition: translate 0.5s ease-in-out;
}
.box--empty .mails {
  display: flex;
}
.mails::-webkit-scrollbar {
  width: 0;
}
.mails__list {
  padding-bottom: 87px;
}
.box--empty .mails__list {
  display: none;
}
.appearance + .mails__list {
  animation: move-down-animation 0.5s ease-in-out forwards;
}
@keyframes move-down-animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(37px);
  }
}
.disappearance + .mails__list {
  animation: move-up-animation 0.5s ease-in-out forwards;
}
@keyframes move-up-animation {
  0% {
    transform: translateY(37px);
  }
  100% {
    transform: translateY(0);
  }
}
.sticky + .mails__list {
  transition: none;
  animation: none;
  transform: translateY(0);
}
.mails__chain {
  border-bottom: 2px solid #0080D8;
}
.mails__chain-bottom {
  display: flex;
  justify-content: space-between;
  background-color: #F0F8FF;
  padding: 4px 24px;
}
.mails__more, .mails__collapse {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #0080D8;
  background-color: #F0F8FF;
  border: none;
  outline: none;
  padding: 4px 4px 4px 0;
  border-radius: 8px;
  transition: 0.1s ease-in-out;
}
.mails__more::before, .mails__collapse::before {
  font-size: 28px;
  line-height: 28px;
  transform: rotate(-180deg);
}
.mails__more:active, .mails__collapse:active {
  background-color: #bde0ff;
}
.mails__empty-block {
  width: max-content;
  margin: auto;
}
.mails__icon {
  display: block;
  width: max-content;
  height: max-content;
  margin: auto;
}
.mails__icon::before {
  font-size: 150px;
  line-height: 150px;
  color: #C4C4C4;
}
.mails__text {
  font-family: "Manrope";
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  color: #C4C4C4;
  text-align: center;
}

@media (min-width: 768px) {
  .mails {
    border-radius: 8px 8px 8px 8px;
    margin: 0;
  }
  .mails__list {
    border-radius: 0 0 8px 8px;
    overflow-y: hidden;
    padding-bottom: 0;
  }
  .appearance + .mails__list {
    animation: move-down-animation 0.5s ease-in-out forwards;
  }
  @keyframes move-down-animation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(37px);
    }
  }
  .sticky + .mails__list {
    transition: none;
    animation: none;
    transform: translateY(0);
  }
  .mails__item:first-child {
    border-radius: 8px 8px 0 0;
  }
  .mails__item:last-child {
    border-radius: 0 0 8px 8px;
  }
  .mails__new-mail {
    display: none;
  }
  .box--split .mails {
    order: 1;
    border-radius: 8px;
    height: calc(100vh - 50px - 48px - 56px - 24px);
    min-height: auto;
    min-width: 370px;
  }
}
@media (min-width: 1200px) {
  .mails__more:active, .mails__collapse:active {
    background-color: #bde0ff;
  }
}
@media (max-width: 1300px) {
  .box--split .mails {
    display: none;
  }
}
.mail-mini {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 8px;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  background-color: #F3F4F6;
  border-top: 1px solid #F3F4F6;
  border-left: 1px solid #F3F4F6;
  border-right: 1px solid #F3F4F6;
}
.mails--slim .mail-mini {
  padding: 8px 11px;
}
.mail-mini:not(.mails__item:last-child .mail-mini):not(.mail-mini--active) {
  border-bottom: 1px solid #E5E7EB;
}
.mails__chain .mail-mini {
  padding-left: 42px;
}
.mail-mini--unread {
  background-color: #FFFFFF;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.mail-mini__inner {
  display: flex;
  flex-flow: row wrap;
  margin-left: 8px;
  row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 32px);
}
.mail-mini__addresses {
  display: flex;
  align-items: center;
  order: 2;
  width: calc(100% - 92px);
  padding-right: 6px;
  column-gap: 4px;
}
.mail-mini__avatar-wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  margin-bottom: auto;
}
.mail-mini__nickname {
  font-family: "Manrope";
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  position: relative;
  color: #384250;
  transition: color 0.2s ease-in-out;
  order: 2;
  max-width: 100%;
}
.mail-mini--show-email .mail-mini__nickname {
  max-width: 45%;
}
.mail-mini--unread .mail-mini__nickname {
  font-weight: 800;
}
.mail-mini__nickname:focus, .mail-mini__nickname:hover {
  color: #384250;
}
.mail-mini__string {
  position: relative;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
}
.mail-mini__string::before {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  line-height: 24px;
  font-size: 24px;
  color: #0080D8;
}
.mail-mini__nickname--contacts .mail-mini__string {
  padding-right: 24px;
}
.mail-mini__to {
  display: none;
}
.mail-mini--show-email .mail-mini__to {
  display: flex;
  align-items: center;
  order: 3;
  font-size: 14px;
  font-weight: 500;
  color: #9da4ae;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 55%;
}
.mail-mini__chain-toggle {
  display: flex;
  align-items: center;
  font-family: "Manrope";
  font-size: 12px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  margin-right: 8px;
  padding-left: 0;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #6C737F;
  background-color: #E5E7EB;
  order: 4;
  border: 2px solid #6C737F;
}
.mail-mini__chain-toggle.collapsed {
  border: 2px solid #E5E7EB;
}
.mail-mini__chain-toggle.collapsed::before {
  transform: rotate(0deg);
}
.mail-mini__chain-toggle::before {
  font-size: 20px;
  line-height: 22px;
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
}
.mail-mini__title {
  font-family: "Manrope";
  font-size: 13px;
  line-height: 27px;
  font-weight: 400;
  font-style: normal;
  color: #384250;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  transition: color 0.2s ease-in-out;
  order: 6;
  width: calc(100% - 165px);
  flex-grow: 1;
}
.mail-mini--unread .mail-mini__title {
  font-weight: 800;
}
.mail-mini__body {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
  font-style: normal;
}
.mails--slim .mail-mini__body {
  padding-right: 6px;
  width: calc(100% - 342px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  text-align: left;
}
.mail-mini__chain-toggle ~ .mail-mini__body {
  width: calc(100% - 445px);
}
.mail-mini__date {
  font-family: "Manrope";
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  margin-left: auto;
  margin-right: 2px;
  color: #384250;
  white-space: nowrap;
  order: 3;
  text-align: right;
  min-width: 70px;
  overflow: hidden;
  transition: font-weight 0.2s, color 0.2s;
  opacity: 1;
}
.mail-mini--unread .mail-mini__date {
  font-weight: 800;
}
.mail-mini__add-favorite {
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  order: 1;
  margin-left: -5px;
}
.mail-mini__add-favorite::before {
  color: #c6c9cf;
  line-height: 24px;
  font-size: 25px;
  transition: 0.2s ease-in-out;
}
.mail-mini__add-favorite.icon.icon-favorites-stroke:hover::before {
  color: #0080D8;
}
.mail-mini__add-favorite.icon.icon-favorites-fill::before {
  color: #0080D8;
}
.mail-mini__add-favorite.icon.icon-favorites-fill:hover::before {
  color: #0062a5;
}
.mail-mini__button {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  background-color: #6C737F;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
  font-family: "Manrope";
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  padding: 2px 8px;
}
.mail-mini__button:focus {
  outline: none;
}
.mail-mini__dropdown-menu {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  font-style: normal;
  border: none;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  min-width: 162px;
}
.mail-mini__dropdown-menu.show {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.mail-mini__dropdown-item {
  min-width: 47%;
}
.mail-mini__labels {
  margin-right: 8px;
  order: 5;
}
.mail-mini__label-name {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  font-style: normal;
  display: block;
  padding: 2px 8px;
  border-radius: 8px;
  color: #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70px;
  margin-left: 8px;
  text-align: center;
}
.mail-mini__dropdown-menu .mail-mini__label-name {
  display: block;
  margin-left: 0;
}
.mail-mini__dropdown-menu .mail-mini__label-name:not(:last-child) {
  margin-right: 8px;
}
.mail-mini__attachments, .mail-mini__encrypted {
  display: block;
  height: 30px;
  width: 24px;
  margin-left: 8px;
}
.mail-mini__attachments::before, .mail-mini__encrypted::before {
  color: #101828;
  line-height: 30px;
  font-size: 25px;
}
.mails--slim .mail-mini__attachments, .mails--slim .mail-mini__encrypted {
  margin-right: 2px;
}
.mail-mini__attachments {
  order: 6;
}
.mail-mini__encrypted {
  order: 7;
}
.mail-mini__actions {
  display: none;
}

@media (min-width: 576px) {
  .mail-mini__labels {
    display: flex;
  }
  .mail-mini__title {
    width: calc(100% - 173px);
  }
}
@media (min-width: 768px) {
  .mail-mini__nickname, .mail-mini__date, .mail-mini__title {
    font-size: 14px;
  }
  .mail-mini--active {
    background-color: #F0F8FF;
    border: 1px solid #0080D8;
  }
  .mails__item:first-child .mail-mini--active:not(.mails__chain .mail-mini--active) {
    border-radius: 8px 8px 0 0;
  }
  .notification + .mails__list .mail-mini--active {
    border-radius: 0;
  }
  .mails__item:last-child .mail-mini--active {
    border-radius: 0 0 8px 8px;
  }
}
@media (min-width: 992px) {
  .mail-mini:hover {
    background-color: #F0F8FF;
    border-color: #F0F8FF;
  }
  .mail-mini--active:hover {
    border-color: #0080D8;
  }
  .mails--slim .mail-mini__avatar-wrapper {
    margin: auto 0;
  }
  .mails--slim .mail-mini__inner {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }
  .mails--slim .mail-mini__add-favorite {
    margin-left: 0;
  }
  .mails--slim .mail-mini__addresses {
    width: auto;
    min-width: 230px;
    max-width: 230px;
  }
  .mails--slim .mail-mini--show-email .mail-mini__addresses {
    min-width: 465px;
    max-width: 465px;
  }
  .box--split .mails--slim .mail-mini--show-email .mail-mini__addresses {
    width: auto;
    min-width: 217px;
    max-width: 217px;
  }
  .mail-mini__nickname {
    order: 2;
    max-width: 100%;
  }
  .mails--slim .mail-mini--show-email .mail-mini__nickname {
    margin-left: 0;
    max-width: 45%;
    min-width: 45%;
  }
  .mails--slim .mail-mini--show-email .mail-mini__to {
    max-width: 55%;
    min-width: 55%;
  }
  .mails--slim .mail-mini__add-favorite {
    order: 1;
    margin-right: 8px;
  }
  .mails--slim .mail-mini__chain-toggle {
    order: 3;
  }
  .mail-mini__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 5px;
    right: 3px;
    opacity: 0;
    background-color: #F0F8FF;
    z-index: 1;
  }
  .mail-mini__actions::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -21px;
    height: 100%;
    width: 24px;
    background: linear-gradient(to right, transparent, #F0F8FF 50%);
  }
  .mails--slim .mail-mini__actions {
    top: 50%;
    transform: translateY(-50%);
  }
  .mail-mini:hover .mail-mini__actions {
    opacity: 1;
  }
  .mails--slim .mail-mini__date {
    order: 8;
    margin-left: 8px;
    min-width: 85px;
  }
  .mail-mini:hover .mail-mini__date {
    opacity: 0;
  }
  .mails--slim .mail-mini:hover .mail-mini__date, .mails--slim .mail-mini:hover .mail-mini__attachments, .mails--slim .mail-mini:hover .mail-mini__encrypted {
    opacity: 0;
  }
}
@media (min-width: 992px) {
  .mail-mini__avatar-wrapper {
    position: relative;
  }
  .mail-mini__avatar-wrapper::before, .mail-mini__avatar-wrapper::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  .mail-mini__avatar-wrapper::before {
    width: 40px;
    height: 40px;
  }
  .mails--slim .mail-mini__avatar-wrapper::before {
    width: 42px;
    height: 42px;
  }
  .mail-mini__avatar-wrapper::after {
    width: 0;
    height: 0;
    transition: width 0.2s, height 0.2s ease-out;
  }
  .mail-mini__avatar-wrapper:hover::after {
    width: 40px;
    height: 40px;
    background-color: #384250;
    opacity: 0.1;
  }
  .mails--slim .mail-mini__avatar-wrapper:hover::after {
    width: 42px;
    height: 42px;
  }
}
@media (min-width: 1400px) {
  .mail-mini {
    container-type: inline-size;
  }
  @container (min-width: 856px) {
    .box--split .mails--slim .mail-mini--show-email .mail-mini__addresses {
      min-width: 470px;
      max-width: 470px;
    }
  }
}
.mail {
  animation: smooth-appearance 0.4s ease-in-out forwards;
  height: calc(100% - 48px);
  position: relative;
}
.box--split .mail {
  width: 100% !important;
}
.mail__container {
  height: max-content;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-top: 16px;
  padding-left: 8px;
  background-color: #FFFFFF;
  scrollbar-color: #0080D8 #F0F8FF;
  scrollbar-width: none;
}
.mail__container::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
}
.mail__container::-webkit-scrollbar-track {
  border-radius: 0;
}
.mail__container::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 0;
}
.mail__header {
  position: relative;
}
.mail__content {
  word-break: break-word;
  min-height: 100px;
  overflow-y: hidden;
  scrollbar-color: #0080D8 #F0F8FF;
  scrollbar-width: auto;
  overflow-x: auto;
}
.mail__content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.mail__content::-webkit-scrollbar-track {
  border-radius: 10px;
}
.mail__content::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}
.mail__title {
  font-family: "Manrope";
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
  position: relative;
  padding: 0 42px 0 8px;
  word-break: break-word;
  white-space: normal;
  min-height: 32px;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mail__labels {
  display: none;
  padding: 0 45px 0 16px;
}
.mail--labeled .mail__labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.mail__label-name {
  display: inline-block;
  vertical-align: middle;
  max-width: 150px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
}
.mail--labeled .mail__label-name {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  white-space: nowrap;
  margin-right: 8px;
  color: #FFFFFF;
  border-radius: 8px;
  background-color: #7452DA;
  padding: 2px 8px;
}
.mail__date, .mail__files {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
}
.mail__panel {
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;
}
.mail__info {
  padding: 0 8px 16px 8px;
  border-bottom: 1px solid #EEEEEE;
}
.mail__users {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
  margin-left: 8px;
  width: calc(100% - 158px);
  flex-grow: 1;
}
.mail__dropdown {
  position: relative;
  width: 100%;
}
.mail__dropdown-menu {
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  padding: 8px;
  max-width: 300px;
  min-width: 200px;
  max-height: 300px;
  overflow: auto;
  display: none;
  background-color: #FFFFFF;
  z-index: 2;
}
.mail__dropdown-menu::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  border-radius: 32px;
  cursor: pointer;
}
.mail__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 0 32px 32px 0;
}
.mail__dropdown-menu--active {
  display: block;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.new-mail__dropdown--type-container .mail__dropdown-menu--active {
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  gap: 0;
}
.mail__list {
  display: flex;
}
.mail__item {
  cursor: pointer;
}
.mail__item_hide {
  display: none;
}
.mail__list--left .mail__item {
  margin-right: 8px;
}
.mail__list--right .mail__item:not(:last-child) {
  margin-right: 8px;
}
.mail__button {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #101828;
  background-color: transparent;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: max-content;
}
.mail__button:focus {
  outline: none;
}
.mail__button--active {
  color: #0080D8;
}
.mail__header .mail__button {
  padding: 4px;
}
.mail__button::before {
  font-size: 32px;
  line-height: 32px;
  vertical-align: middle;
}
.mail__button:active {
  background-color: #F0F8FF;
}
.mail__button--close {
  position: absolute;
  top: -9px;
  right: 3px;
  color: #C4C4C4;
  z-index: 2;
}
.mail__button--full, .mail__button--scale {
  display: none;
}
.mail__button--files {
  position: relative;
  padding-left: 52px;
  border-radius: 8px;
  text-align: left;
  width: 100%;
  background-color: #F0F8FF;
}
.mail__button--files::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;
  right: 8px;
  color: #0080D8;
  font-size: 32px;
}
.mail__button--files.collapsed {
  background-color: #F3F4F6;
}
.mail__button--files.collapsed::before {
  transform: translateY(-50%) rotate(-180deg);
}
.mail__button--images {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  width: 100%;
  padding: 4px;
  background-color: #F3F4F6;
  color: #0080D8;
  margin-bottom: 16px;
}
.mail__text {
  position: relative;
  color: #0080D8;
}
.mail__text::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.mail__button--files .mail__text::before {
  left: -40px;
  font-size: 32px;
}
.mail__line {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  align-items: center;
}
.mail__line:first-child {
  justify-content: space-between;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 16px;
}
.mail__line:nth-child(2) {
  margin-top: 14px;
  margin-bottom: 40px;
}
.mail--blured .mail__content {
  background-color: #FFFFFF;
  filter: blur(15px);
  pointer-events: none;
}
.mail__files {
  padding-right: 32px;
  position: relative;
}
.mail__files-count {
  margin-right: 8px;
}
.mail__files-weight {
  position: relative;
  color: #808086;
}
.mail__files-weight:before {
  position: absolute;
  top: 50%;
  font-size: 30px;
  line-height: 30px;
  right: -34px;
  transform: translateY(-50%);
  color: #808086;
}
.mail__dropdown-menu .mail__files-weight {
  display: inline-block;
  transition: 0.2s ease-in-out;
  margin-left: 16px;
}
.mail__files-accordion {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  padding: 16px 8px;
  background-color: #FFFFFF;
}
.mail--blured .mail__files-accordion {
  background-color: #FFFFFF;
  filter: blur(15px);
  pointer-events: none;
}
.mail--attachments .mail__files-accordion {
  display: block;
}
.mail__files-content .attaches {
  margin-top: 8px;
}
.mail__from, .mail__to {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  display: block;
  white-space: nowrap;
  min-width: 55px;
  padding-top: 4px;
  text-align: right;
}
.mail__body {
  position: relative;
  padding: 16px 8px 0 8px;
}
.mail__content {
  overflow-x: auto;
  min-height: 160px;
}
.mail__body:has(.mail__files-accordion) .mail__content {
  margin-bottom: 75px;
  padding-bottom: 14px;
}
.mail__footer {
  display: none;
  margin-top: auto;
}
.mail__actions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
}
.mail__attachments {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  margin-top: 16px;
  column-gap: 2%;
  row-gap: 8px;
  max-height: 400px;
  overflow-y: auto;
}
.mail__attachments::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.mail__attachments::-webkit-scrollbar-track {
  border-radius: 10px;
}
.mail__attachments::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}

@media (min-width: 576px) {
  .mail__item_hide {
    display: flex;
  }
  .mail__attachments {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .mail {
    max-height: 100vh;
    height: 100%;
    border-radius: 8px;
    overflow-x: auto;
  }
  .box--split .mail {
    position: relative;
    height: calc(100vh - 50px - 48px - 56px - 24px);
    min-height: auto;
  }
  .mail__container {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .mail__container::-webkit-scrollbar, .mail__content::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  .mail__container::-webkit-scrollbar, .mail__container::-webkit-scrollbar-track, .mail__container::-webkit-scrollbar-thumb, .mail__content::-webkit-scrollbar, .mail__content::-webkit-scrollbar-track, .mail__content::-webkit-scrollbar-thumb {
    border-radius: 0 8px 8px 0;
  }
  .mail__container::-webkit-scrollbar-thumb, .mail__content::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .mail__container:hover::-webkit-scrollbar-thumb, .mail__content:hover::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #0080D8;
  }
  .mail__body {
    flex-grow: 1;
    padding: 16px 8px;
  }
  .mail__title {
    padding: 0 86px 0 16px;
  }
  .mail__actions {
    flex-direction: row;
    column-gap: 8px;
  }
  .box--split .mail__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }
  .box--split .mail__list--left .mail__item {
    margin-right: 4px;
  }
  .box--split .mail__list--right .mail__item:not(:last-child) {
    margin-right: 4px;
  }
  .mail__footer .mail__button {
    margin-right: 8px;
  }
  .mail__button--files {
    padding-left: 52px;
  }
  .mail__button--forward, .mail__button--reply-all, .mail__button--reply {
    width: max-content;
    padding-right: 52px;
    margin-bottom: 0;
  }
  .box--split .mail__button--forward, .box--split .mail__button--reply-all, .box--split .mail__button--reply {
    flex-grow: 1;
  }
  .mail--blured .mail__footer {
    display: none;
  }
  .mail__attachments {
    justify-content: flex-start;
  }
}
@media (max-width: 991px) {
  .box--split .mail {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .box--split .mail {
    flex: 1 1 0%;
    order: 3;
  }
  .mail__title {
    font-family: "Manrope";
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-style: normal;
  }
  .mail__footer {
    display: block;
  }
}
@media (min-width: 1200px) {
  .box--split .mail__list--left .mail__item {
    margin-right: 8px;
  }
  .box--split .mail__list--right .mail__item:not(:last-child) {
    margin-right: 8px;
  }
  .mail__button--forward:hover, .mail__button--reply-all:hover, .mail__button--reply:hover {
    background-color: #bde0ff;
  }
  .mail__button--forward:active, .mail__button--reply-all:active, .mail__button--reply:active {
    background-color: #8ac8ff;
  }
  .mail__button:hover {
    background-color: #F0F8FF;
  }
  .mail__button:active {
    background-color: #bde0ff;
  }
}
@media (min-width: 1300px) {
  .mail__button--full, .mail__button--scale {
    display: block;
    position: absolute;
    top: -9px;
    right: 45px;
    color: #C4C4C4;
    z-index: 2;
  }
}
.new-mail {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  overflow: auto;
  background-color: #FFFFFF;
  height: 100%;
}
.new-mail--collapsed {
  height: auto;
  width: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  max-width: 415px;
  min-width: 320px;
  right: 0;
  top: auto;
  bottom: 0;
  border-radius: 8px;
}
.new-mail--collapsed .new-mail__wrapper {
  width: 100%;
  min-height: auto;
  min-width: auto;
}
.new-mail--collapsed .new-mail__line,
.new-mail--collapsed .new-mail__accordion,
.new-mail--collapsed .new-mail__content,
.new-mail--collapsed .new-mail__footer,
.new-mail--collapsed .new-mail__line {
  display: none;
}
.new-mail--collapsed .new-mail__title {
  border-radius: 8px;
  background-color: #0080D8;
  color: #FFFFFF;
}
.new-mail--collapsed .icon-full::before,
.new-mail--collapsed .icon-minus::before,
.new-mail--collapsed .icon-close::before {
  color: #FFFFFF;
}
.new-mail--fullscreen .new-mail__wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.new-mail__container {
  min-width: 320px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.new-mail__wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 auto;
  border-radius: 16px;
  background-color: #FFFFFF;
  flex: 1 1 0;
}
.new-mail__wrapper-inner {
  display: flex;
  height: 100%;
}
.new-mail__form {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}
.new-mail__form::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  background-color: #F0F8FF;
  border-radius: 2px;
  cursor: pointer;
}
.new-mail__form::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 2px;
}
.new-mail__header {
  position: relative;
}
.new-mail__title {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  padding: 16px;
  background-color: #E5E7EB;
}
.new-mail__list {
  display: flex;
  position: absolute;
  top: 11px;
  right: 6px;
}
.new-mail__line {
  position: relative;
  border-bottom: 1px solid #E5E7EB;
  padding: 8px;
}
.new-mail__line--to {
  padding-right: 40px;
}
.new-mail__line--title {
  display: flex;
  align-items: center;
}
.new-mail__label {
  display: block;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 400;
  font-style: normal;
  color: #B8C0D0;
  width: 115px;
  margin-bottom: 8px;
}
.new-mail__line--title .new-mail__label {
  margin: 0;
}
.new-mail__accordion {
  position: relative;
  width: 100%;
}
.new-mail__button {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  position: relative;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  transition: 0.15s ease-in-out;
}
.new-mail__button--accordion {
  position: absolute;
  top: 7px;
  right: 8px;
  z-index: 1;
  background: none;
  padding: 0;
}
.new-mail__button--toggle {
  display: flex;
  align-items: center;
  color: #0080D8;
  background-color: #E5E7EB;
  padding: 5px 8px 5px 5px;
}
.new-mail__button--toggle:hover {
  color: #FFFFFF;
  background-color: #0080D8;
}
.new-mail__button--add {
  position: relative;
  cursor: pointer;
  padding: 4px;
  margin-right: 4px;
}
.new-mail__button--add:active {
  background-color: #bde0ff;
}
.new-mail__button--add input {
  width: 30px;
  height: 30px;
  font-size: 0;
  position: absolute;
  left: 0;
  border: none;
  background: none;
  opacity: 0;
  cursor: pointer;
}
.new-mail__button--copy {
  padding: 0;
  margin-right: 8px;
}
.new-mail__button--active {
  color: #FFFFFF;
  background-color: #0080D8;
}
.new-mail__icon {
  display: block;
  height: 30px;
  transition: 0.15s ease-in-out;
}
.new-mail__icon::after {
  position: absolute;
}
.new-mail__icon::before {
  font-size: 30px;
  line-height: 30px;
  height: 30px;
  color: #0080D8;
  transition: 0.15s ease-in-out;
}
.new-mail__footer .new-mail__icon::before {
  color: #384250;
  cursor: pointer !important;
}
.new-mail__button--accordion.collapsed .new-mail__icon {
  transform: rotate(0);
}
.new-mail__button--accordion .new-mail__icon {
  transform: rotate(-180deg);
}
.new-mail__button--accordion .new-mail__icon::before {
  color: #101828;
}
.new-mail__button--toggle .new-mail__icon {
  height: 24px;
}
.new-mail__button--toggle .new-mail__icon::before {
  font-size: 24px;
  line-height: 24px;
  color: #0080D8;
}
.new-mail__button--toggle:hover .new-mail__icon::before, .new-mail__button--active .new-mail__icon::before {
  color: #FFFFFF;
}
.new-mail__dropdown {
  position: relative;
  width: 100%;
}
.new-mail__dropdown-menu {
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  padding: 8px;
  max-width: 300px;
  min-width: 200px;
  max-height: 300px;
  overflow: auto;
  display: none;
  background-color: #FFFFFF;
  z-index: 2;
}
.new-mail__dropdown-menu::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  border-radius: 32px;
  cursor: pointer;
}
.new-mail__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 0 32px 32px 0;
}
.new-mail__dropdown-menu--active {
  display: block;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.new-mail__dropdown--type-container .new-mail__dropdown-menu--active {
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  gap: 0;
}
.new-mail__item {
  cursor: pointer;
}
.new-mail__dropdown--type-container .new-mail__item {
  padding: 8px;
  transition: 0.2s ease-in-out;
}
.new-mail__dropdown--type-container .new-mail__item:hover {
  background-color: #F0F8FF;
}
.new-mail__tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}
.new-mail__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 24px 8px 0 8px;
}
.new-mail__content::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  background-color: #F0F8FF;
  border-radius: 2px;
  cursor: pointer;
}
.new-mail__content::-webkit-scrollbar-thumb {
  background-color: #0080D8;
  border-radius: 2px;
}
.new-mail__mail-title {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  border: none;
  width: 100%;
  padding: 0;
  height: 34px;
}
.new-mail__mail-title:focus {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  outline: none;
}
.new-mail__area {
  height: max-content;
  flex-grow: 1;
}
.new-mail__attachments {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 8px;
  margin-top: 24px;
}
.new-mail__footer {
  display: flex;
  align-items: center;
  padding: 8px;
}
.new-mail__encrypt {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.new-mail__text {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
}
.new-mail__text--default {
  color: #6C737F;
  margin: 0 8px;
  user-select: none;
}
.new-mail__text--password {
  color: #384250;
  padding: 8px 0;
  max-width: 97px;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  border: none;
  border-bottom: 1px solid #6C737F;
  cursor: pointer;
  border-radius: 0;
}
.switcher__checkbox:checked ~ .new-mail__text--password {
  display: block;
}

@media (min-width: 768px) {
  .new-mail--collapsed {
    right: 24px;
    bottom: 24px;
  }
  .new-mail__text {
    font-size: 14px;
    max-width: 185px;
  }
}
@media (min-width: 1200px) {
  .new-mail {
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(32, 40, 64, 0.35);
  }
  .new-mail--fullscreen .new-mail__wrapper {
    max-height: none;
  }
  .new-mail--fullscreen .new-mail__form {
    width: 100%;
  }
  .new-mail--fullscreen .new-mail__title {
    border-radius: 0;
  }
  .new-mail--collapsed {
    top: auto;
    left: auto;
    transform: none;
    background-color: transparent;
  }
  .new-mail__container {
    height: 100%;
    flex-direction: row;
  }
  .new-mail__wrapper {
    flex: none;
    min-width: 700px;
    min-height: 470px;
    max-height: 1000px;
    width: 50%;
    height: calc(100% - 16px);
  }
  .new-mail__wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .new-mail__form {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .new-mail__title {
    border-radius: 16px 16px 0 0;
  }
  .new-mail__label {
    width: 95px;
    margin: 0;
  }
  .new-mail__button--accordion {
    right: 24px;
  }
  .new-mail__button--add:hover {
    background-color: #F0F8FF;
  }
  .new-mail__button--add:active {
    background-color: #bde0ff;
  }
  .new-mail__line {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 16px;
  }
  .new-mail__content {
    margin: 0 8px;
  }
  .new-mail__footer {
    padding: 16px;
  }
}
.settings-content {
  background-color: #FFFFFF;
  padding: 16px;
  overflow-y: auto;
  margin-top: 0;
  flex-direction: column;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
}
.settings-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.settings-content__content-group, .settings-content__block {
  display: none;
  animation: smooth-appearance 0.4s ease-in-out forwards;
  height: 100%;
  position: relative;
}
.settings-content__content-group--active, .settings-content__block--active {
  display: block;
}
.settings-content__creation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.settings-content__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.settings-content__fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.settings-content__legend {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 8px;
  display: block;
}
.settings-content__legend:not(:first-of-type) {
  margin-top: 24px;
}
.settings-content__title {
  font-family: "Manrope";
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 8px;
  width: 100%;
  text-transform: uppercase;
}
.settings-content__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  display: block;
  color: #6C737F;
  margin-top: 24px;
  margin-bottom: 8px;
  transition: 0.2s ease-in-out;
  width: 100%;
}
.settings-content__label--small {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.settings-content__label--checkbox {
  display: inline-block;
  position: relative;
  padding: 16px 48px;
  background-color: #F0F8FF;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  width: 100%;
}
.settings-content__label--checkbox::before, .settings-content__label--checkbox::after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 10px;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
}
.settings-content__label--checkbox::before {
  border: 1px solid #B8C0D0;
}
.settings-content__checkbox:checked + .settings-content__label--checkbox {
  color: #0080D8;
}
.settings-content__label--checkbox:hover, .settings-content__label--checkbox:focus {
  outline: none;
}
.settings-content__label--tab {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
  width: 100%;
  color: #0080D8;
  background-color: #F0F8FF;
  border-radius: 8px;
  padding: 8px 42px;
  margin: 0;
  text-align: center;
  word-break: break-word;
}
.settings-content__checkbox:checked + .settings-content__label--tab {
  color: #FFFFFF;
  background-color: #0080D8;
}
.settings-content__button {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
  font-style: normal;
}
.settings-content__button--add-label, .settings-content__button--add-folder, .settings-content__button--add-profile, .settings-content__button--expand {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #0080D8;
  background-color: #F0F8FF;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: 100%;
}
.settings-content__button--add-label:focus, .settings-content__button--add-folder:focus, .settings-content__button--add-profile:focus, .settings-content__button--expand:focus {
  outline: none;
}
.settings-content__button--add-label:active, .settings-content__button--add-folder:active, .settings-content__button--add-profile:active, .settings-content__button--expand:active {
  background-color: #bde0ff;
}
.settings-content__button--submit {
  padding: 14px;
  white-space: normal;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  background-color: #0080D8;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  width: 100%;
}
.settings-content__button--submit:focus {
  outline: none;
}
.settings-content__button--submit:active {
  background-color: #0c9cff;
}
.settings-content__button--expand {
  padding: 8px;
}
.settings-content__button-text {
  position: relative;
  padding-left: 38px;
  user-select: none;
}
.settings-content__button--add-label .settings-content__button-text:before, .settings-content__button--add-folder .settings-content__button-text:before, .settings-content__button--add-profile .settings-content__button-text:before, .settings-content__button--submit .settings-content__button-text:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
}
.settings-content__container {
  width: 100%;
  margin-top: 24px;
}
.settings-content__dropdown-menu {
  width: max-content;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
}
.settings-content__dropdown-item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}
.settings-content__dropdown-item:active {
  background-color: #F0F8FF;
}
.settings-content__list--decimal {
  padding-left: 16px;
  list-style-type: decimal;
  width: 100%;
}
.settings-content__item {
  position: relative;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 8px 0;
}
.settings-content__list--decimal .settings-content__item {
  font-weight: 400;
  padding-left: 8px;
}
.settings-content__link {
  color: #0080D8;
}
.settings-content__checkbox {
  display: none;
}
.settings-content__element-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: #F3F4F6;
  border-radius: 8px;
  padding: 12px;
}
.settings-content__element-inner {
  width: calc(100% - 157px);
  flex-grow: 1;
}
.settings-content__element-icon {
  display: block;
  height: 30px;
  margin-right: 8px;
}
.settings-content__element-icon::before {
  font-size: 30px;
  line-height: 30px;
  color: #0080D8;
}
.settings-content__element-block--label .settings-content__element-icon:before {
  color: #F3F4F6;
}
.settings-content__element-name {
  color: #6C737F;
  width: calc(100% - 155px);
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
}
.settings-content__element-block--verified .settings-content__element-name {
  width: calc(100% - 190px);
}
.settings-content__element-inner .settings-content__element-name {
  width: auto;
}
.settings-content__element-value {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
}
.settings-content__element-status:before {
  font-size: 40px;
  line-height: 40px;
  color: #228866;
}
.settings-content__editor {
  font-family: "Manrope";
  font-size: 24px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  display: flex;
  width: 100%;
  height: 140px;
  border-radius: 8px;
  background-color: #F0F8FF;
  margin-top: 32px;
}
.settings-content__editor p {
  margin: auto;
}
.settings-content__notification {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
}
.settings-content__wrapper {
  width: 100%;
  margin: 24px 0 0 0;
  max-height: 470px;
  overflow-x: auto;
  overflow-y: auto;
  outline: 1px solid #EEEEEE;
  border-radius: 2px;
}
.settings-content__wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.settings-content__wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.settings-content__wrapper::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}
.settings-content__table {
  margin-bottom: 24px;
  position: relative;
}
.settings-content__row {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 144%;
  font-weight: 500;
  font-style: normal;
  color: #101828;
}
.settings-content__row--head {
  position: sticky;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #808086;
  background-color: #FFFFFF;
}
.settings-content__row:nth-child(2n+1):not(:nth-child(1)) {
  background-color: #EEEEEE;
}
.settings-content__row:nth-child(2n) {
  background-color: #F5F5F5;
}
.settings-content__cell {
  padding: 24px 16px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.settings-content__cell:first-child {
  text-align: center;
  max-width: 80px;
}
.settings-content__cell:nth-child(2) {
  max-width: 140px;
}
.settings-content__cell:nth-child(3) {
  max-width: 201px;
}
.settings-content__cell:nth-child(4) {
  max-width: 160px;
}
.settings-content__cell:last-child {
  width: 100%;
}
.settings-content__paragraph {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #384250;
}
.settings-content__paragraph--hint {
  display: flex;
  align-items: center;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F8C020;
  background-color: #FEF7D2;
  word-break: break-word;
  width: 100%;
}
.settings-content__paragraph--code {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  padding: 24px 16px;
  border-radius: 8px;
  border: 1px solid #D2D6DB;
  background-color: #F0F8FF;
  white-space: pre-line;
  word-break: break-all;
  width: 100%;
  overflow: auto;
}
.settings-content__paragraph:first-of-type {
  margin-top: 24px;
}
.settings-content__paragraph:not(:last-of-type) {
  margin-bottom: 16px;
}
.settings-content__text {
  display: block;
}

@media (min-width: 400px) {
  .settings-content__field {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 8px;
  }
}
@media (min-width: 576px) {
  .settings-content__fieldset--wide {
    max-width: none;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .settings-content {
    padding: 32px 4px 32px 24px;
    border-radius: 8px;
  }
  .settings-content__content-group {
    overflow: auto;
  }
  .settings-content__content-group::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  .settings-content__content-group::-webkit-scrollbar, .settings-content__content-group::-webkit-scrollbar-track, .settings-content__content-group::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
  .settings-content__content-group::-webkit-scrollbar-thumb {
    background-color: #808086;
  }
  .settings-content__info {
    width: 100%;
  }
  .settings-content__form {
    flex-direction: column;
  }
  .settings-content__creation, .settings-content__container, .settings-content__fieldset {
    max-width: 400px;
  }
  .settings-content__fieldset--wide {
    max-width: 430px;
  }
  .settings-content__fieldset--extra-wide {
    max-width: none;
    width: 100%;
  }
  .settings-content__button {
    max-width: 400px;
  }
  .settings-content__table {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .settings-content__wrapper {
    margin: 24px 24px 0 1px;
  }
}
.contacts-content {
  background-color: #FFFFFF;
  padding: 32px 16px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
}
.contacts-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.contacts-content__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contacts-content__container {
  width: 100%;
}
.contacts-content__line {
  display: flex;
  align-items: center;
  max-width: 768px;
  margin-bottom: 24px;
}
.contacts-content__total {
  font-family: "Manrope";
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  user-select: none;
}
.contacts-content__counter {
  margin-left: 8px;
  margin-right: 5px;
}
.contacts-content__contacts {
  max-width: 368px;
  margin: 0 auto;
  width: 100%;
}
.contacts-content__menu {
  display: flex;
  align-items: center;
  margin-left: 16px;
  height: 50px;
}
.contacts-content__list--contacts .contacts-content__item {
  display: inline-block;
  margin: 8px 0;
  width: 100%;
}
.contacts-content__list--groups .contacts-content__item {
  margin: 8px 0;
}
.contacts-content__menu .contacts-content__item {
  font-family: "Manrope";
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  display: block;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contacts-content__menu .contacts-content__item:not(:last-child) {
  margin-right: 4px;
}
.contacts-content__contact {
  position: relative;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 390px;
  transition: 0.2s ease-in-out;
}
.contacts-content__item--selected .contacts-content__contact {
  border: 1px solid #0080D8;
}
.contacts-content__contact-block {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-content: flex-start;
  position: relative;
  height: 48px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.contacts-content__avatar-wrapper {
  position: relative;
  margin-right: 8px;
}
.contacts-content__list--active .contacts-content__item--selected .contacts-content__avatar-wrapper {
  position: relative;
}
.contacts-content__list--active .contacts-content__item--selected .contacts-content__avatar-wrapper::before, .contacts-content__list--active .contacts-content__item--selected .contacts-content__avatar-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease-in-out;
}
.contacts-content__list--active .contacts-content__item--selected .contacts-content__avatar-wrapper::before {
  width: 105%;
  height: 105%;
  background-color: #FFFFFF;
}
.contacts-content__nickname {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
}
.contacts-content__nickname:visited {
  color: #6C737F;
}
.contacts-content__email {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  font-style: normal;
  color: #0080D8;
}
.contacts-content__email:visited {
  color: #0080D8;
}
.contacts-content__nickname, .contacts-content__email {
  max-width: calc(83% - 83px);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: 0.1s ease-in-out;
}
.contacts-content__nickname:active, .contacts-content__email:active {
  color: #0062a5;
}
.contacts-content__actions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.contacts-content__button {
  width: max-content;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  padding: 4px;
}
.contacts-content__button--edit, .contacts-content__button--trash {
  position: absolute;
  top: 14px;
}
.contacts-content__button--edit {
  right: 50px;
}
.contacts-content__button--edit:hover {
  background-color: #F0F8FF;
}
.contacts-content__button--edit:active {
  background-color: #bde0ff;
}
.contacts-content__button--trash {
  right: 14px;
}
.contacts-content__button--trash:hover {
  background-color: #FEE7DA;
}
.contacts-content__button--trash:active {
  background-color: #D23442;
}
.contacts-content__icon {
  display: block;
  padding: 0;
  height: 24px;
}
.contacts-content__icon::before {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}
.contacts-content__button--edit .contacts-content__icon::before {
  color: #6C737F;
}
.contacts-content__button--trash .contacts-content__icon::before {
  color: #D23442;
}
.contacts-content__button--trash:active .contacts-content__icon::before {
  color: #FFFFFF;
}
.contacts-content__creation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contacts-content__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contacts-content__fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #EEEEEE;
  width: 100%;
}
.contacts-content__legend {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 8px;
}
.contacts-content__title {
  font-family: "Manrope";
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 8px;
  width: 100%;
  text-transform: uppercase;
}
.contacts-content__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;
}
.contacts-content__label--small {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.contacts-content__notification {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  margin-top: 24px;
}
.contacts-content__dropdown-menu {
  width: max-content;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
}
.contacts-content__dropdown-item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}
.contacts-content__dropdown-item:active {
  background-color: #F0F8FF;
}
.contacts-content__element-block {
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  background-color: #F3F4F6;
  border-radius: 8px;
  padding: 24px;
}
.contacts-content__element-icon {
  display: block;
  height: 30px;
}
.contacts-content__element-icon::before {
  font-size: 30px;
  line-height: 30px;
  color: #0080D8;
}
.contacts-content__element-name {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  margin-left: 8px;
  color: #6C737F;
}
.contacts-content__search-wrapper {
  position: relative;
  width: 100%;
  height: 50px;
  margin-left: 4px;
}
.contacts-content__pagination {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 144%;
  font-weight: 600;
  font-style: normal;
  display: flex;
  align-items: center;
  margin: auto auto 0 auto;
  width: max-content;
  padding-top: 16px;
}

@media (min-width: 576px) {
  .contacts-content__menu .contacts-content__item {
    max-width: 200px;
  }
}
@media (min-width: 768px) {
  .contacts-content {
    border-radius: 8px;
    padding: 24px 4px 0 24px;
  }
  .contacts-content__inner {
    overflow: auto;
  }
  .contacts-content__inner::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  .contacts-content__inner::-webkit-scrollbar, .contacts-content__inner::-webkit-scrollbar-track, .contacts-content__inner::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
  .contacts-content__inner::-webkit-scrollbar-thumb {
    background-color: #808086;
  }
  .contacts-content__total {
    font-size: 22px;
  }
  .contacts-content__form {
    flex-direction: column;
  }
  .contacts-content__fieldset, .contacts-content__creation {
    max-width: 400px;
  }
  .contacts-content__container {
    max-width: 400px;
  }
  .contacts-content__contacts {
    max-width: none;
    margin: 0;
    margin-bottom: 16px;
  }
  .contacts-content__contact {
    max-width: none;
  }
  .contacts-content__nickname, .contacts-content__email {
    max-width: calc(83% - 83px);
  }
  .contacts-content__button--edit {
    right: 37px;
  }
  .contacts-content__button--trash {
    right: 7px;
  }
  .contacts-content__list {
    display: flex;
  }
  .contacts-content__list--contacts {
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 2%;
    row-gap: 16px;
    padding-right: 16px;
  }
  .contacts-content__list--groups {
    flex-direction: column;
  }
  .contacts-content__item {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .contacts-content__list--contacts .contacts-content__item {
    width: 48%;
  }
  .contacts-content__list--active .contacts-content__item {
    cursor: pointer;
  }
  .contacts-content__menu .contacts-content__item {
    margin-right: 8px;
    font-size: 22px;
    line-height: 25px;
  }
}
@media (min-width: 992px) {
  .contacts-content {
    padding: 24px 4px 24px 24px;
  }
  .contacts-content__inner {
    height: 100%;
  }
  .contacts-content__contacts {
    height: 100%;
  }
  .contacts-content__menu {
    margin-left: 24px;
  }
  .contacts-content__list--contacts .contacts-content__item {
    width: 32%;
  }
  .contacts-content__list--active .contacts-content__item {
    cursor: pointer;
  }
  .contacts-content__pagination {
    margin: auto 0 0 0;
  }
}
@media (min-width: 1200px) {
  .contacts-content__contacts {
    max-width: 1700px;
  }
  .contacts-content__list--active .contacts-content__item:hover .contacts-content__avatar-wrapper::before, .contacts-content__list--active .contacts-content__item:hover .contacts-content__avatar-wrapper::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s ease-in-out;
  }
  .contacts-content__list--active .contacts-content__item:hover .contacts-content__avatar-wrapper::before {
    width: 105%;
    height: 105%;
    background-color: #FFFFFF;
  }
  .contacts-content__nickname:hover, .contacts-content__email:hover {
    color: ligthen(#0080D8, 10%);
  }
  .contacts-content__nickname:active, .contacts-content__email:active {
    color: #0062a5;
  }
  .contacts-content__button--edit {
    right: 50px;
  }
  .contacts-content__button--trash {
    right: 10px;
  }
}
.content {
  background-color: #FFFFFF;
  padding: 0 16px 16px 16px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  /* &__more {
      height: 32px;
      width: 32px;
      margin-left: auto;
      border: none;
      outline: none;
      background: $transparent url("/images/icons/Dots.svg") no-repeat 50%;
      background-size: contain;
  } */
}
.content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.content__block {
  display: none;
}
.content__block--active {
  display: block;
}
.content__creation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content__container {
  width: 100%;
  margin-top: 24px;
}
.content__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content__fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin-bottom: 24px;
}
.content__legend {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  margin-top: 24px;
  margin-bottom: 8px;
  display: block;
}
.content__title {
  font-family: "Manrope";
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  margin-top: 32px;
  margin-bottom: 8px;
  width: 100%;
  text-transform: uppercase;
}
.content__label {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  display: block;
  color: #6C737F;
  margin-top: 24px;
  margin-bottom: 8px;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: min-content;
}
.content__label--small {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.content__list {
  width: 100%;
}
.content__list--decimal {
  padding-left: 16px;
  list-style-type: decimal;
  width: 100%;
}
.content__list--contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 2%;
  row-gap: 16px;
}
.content__item {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 8px 0;
}
.content__list--decimal .content__item {
  font-weight: 400;
  padding-left: 8px;
}
.content__list--contacts .content__item {
  width: 100%;
  margin: 8px 0;
}
.content__link {
  color: #0080D8;
}
.content__element-block {
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  background-color: #F3F4F6;
  border-radius: 8px;
  padding: 24px;
}
.content__element-icon {
  position: relative;
  display: block;
  height: 28px;
  width: 28px;
}
.content__element-icon::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  line-height: 30px;
  color: #0080D8;
}
.content__element-block--label .content__element-icon:before {
  color: #F3F4F6;
}
.content__element-name {
  margin-left: 8px;
  color: #6C737F;
  max-width: calc(100% - 93px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content__editor {
  font-family: "Manrope";
  font-size: 24px;
  line-height: 144%;
  font-weight: 700;
  font-style: normal;
  display: flex;
  width: 100%;
  height: 140px;
  border-radius: 8px;
  background-color: #F0F8FF;
  margin-top: 32px;
}
.content__editor p {
  margin: auto;
}
.content__notification {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  max-width: 368px;
  margin-top: 24px;
}
.content__wrapper {
  width: 100%;
  margin-top: 24px;
  max-height: 470px;
  overflow-x: auto;
  overflow-y: auto;
  outline: 1px solid #EEEEEE;
  border-radius: 2px;
}
.content__wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F0F8FF;
  border-radius: 2px;
}
.content__wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.content__wrapper::-webkit-scrollbar-thumb {
  background: #0080D8;
  border-radius: 2px;
}
.content__table {
  margin-bottom: 24px;
  position: relative;
}
.content__row {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 144%;
  font-weight: 500;
  font-style: normal;
  color: #101828;
}
.content__row--head {
  position: sticky;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #808086;
  background-color: #FFFFFF;
}
.content__row:nth-child(2n+1):not(:nth-child(1)) {
  background-color: #EEEEEE;
}
.content__row:nth-child(2n) {
  background-color: #F5F5F5;
}
.content__cell {
  padding: 24px 16px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.content__cell:first-child {
  text-align: center;
  max-width: 80px;
}
.content__cell:nth-child(2) {
  max-width: 140px;
}
.content__cell:nth-child(3) {
  max-width: 201px;
}
.content__cell:nth-child(4) {
  max-width: 160px;
}
.content__cell:last-child {
  width: 100%;
}
.content__paragraph {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #384250;
}
.content__paragraph--hint {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F8C020;
  background-color: #FEF7D2;
}
.content__paragraph--code {
  font-family: "Manrope";
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  padding: 24px 16px;
  border-radius: 8px;
  border: 1px solid #D2D6DB;
  background-color: #F0F8FF;
  white-space: pre-line;
  word-break: break-all;
  width: 100%;
  overflow: auto;
}
.content__paragraph:first-of-type {
  margin-top: 24px;
}
.content__paragraph:not(:last-of-type) {
  margin-bottom: 16px;
}
.content__search-wrapper {
  position: relative;
  top: 0;
  width: 100%;
  height: 50px;
  margin-left: 4px;
}
.content__line {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.content__total {
  font-family: "Manrope";
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  user-select: none;
}
.content__counter {
  margin-left: 8px;
}
.content__contacts {
  max-width: 368px;
  margin: 0 auto;
}
.content__textarea {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  padding: 10px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #B8C0D0;
  margin-top: 8px;
}

@media (min-width: 576px) {
  .content__fieldset--wide {
    max-width: none;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .content {
    border-radius: 8px;
    padding: 24px;
  }
  .content__container {
    max-width: 400px;
  }
  .content__list--contacts .content__item {
    width: 48%;
  }
  .content__list--active .content__item {
    cursor: pointer;
  }
  .content__info {
    width: 100%;
  }
  .content__form {
    flex-direction: column;
  }
  .content__creation, .content__fieldset {
    max-width: 400px;
  }
  .content__fieldset--wide {
    max-width: 430px;
  }
  .content__fieldset--extra-wide {
    max-width: none;
    width: 100%;
  }
  .content__button {
    max-width: 400px;
  }
  .content__table {
    width: 100%;
  }
  .content__contacts {
    max-width: none;
    margin: 0;
    margin-bottom: 16px;
  }
}
@media (min-width: 992px) {
  .content__list {
    justify-content: flex-start;
  }
  .content__list--contacts .content__item {
    width: 32%;
  }
  .content__list--active .content__item {
    cursor: pointer;
  }
}
@media (min-width: 1200px) {
  .content__contacts {
    max-width: 1700px;
  }
}
.contact {
  position: relative;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 390px;
  transition: 0.2s ease-in-out;
}
.content__item--selected .contact {
  border: 1px solid #0080D8;
}
.contact__block {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-content: flex-start;
  position: relative;
  height: 48px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.contact__avatar-wrapper {
  position: relative;
  margin-right: 8px;
}
.contacts-content__list--active .contacts-content__item--selected .contact__avatar-wrapper {
  position: relative;
}
.contacts-content__list--active .contacts-content__item--selected .contact__avatar-wrapper::before, .contacts-content__list--active .contacts-content__item--selected .contact__avatar-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease-in-out;
}
.contacts-content__list--active .contacts-content__item--selected .contact__avatar-wrapper::before {
  width: 105%;
  height: 105%;
  background-color: #FFFFFF;
}
.contact__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background-color: #C4C4C4;
}
.contact__nickname {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #6C737F;
}
.contact__nickname:visited {
  color: #6C737F;
}
.contact__email {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  font-style: normal;
  color: #0080D8;
}
.contact__email:visited {
  color: #0080D8;
}
.contact__nickname, .contact__email {
  max-width: calc(83% - 83px);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: 0.1s ease-in-out;
}
.contact__nickname:active, .contact__email:active {
  color: #0062a5;
}
.contact__actions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.contact__button {
  width: max-content;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  padding: 4px;
}
.contact__button--edit, .contact__button--trash {
  position: absolute;
  top: 14px;
}
.contact__button--edit {
  right: 50px;
}
.contact__button--edit:hover {
  background-color: #F0F8FF;
}
.contact__button--edit:active {
  background-color: #bde0ff;
}
.contact__button--trash {
  right: 14px;
}
.contact__button--trash:hover {
  background-color: #FEE7DA;
}
.contact__button--trash:active {
  background-color: #D23442;
}
.contact__icon {
  display: block;
  padding: 0;
  height: 24px;
}
.contact__icon::before {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}
.contact__button--edit .contact__icon::before {
  color: #6C737F;
}
.contact__button--trash .contact__icon::before {
  color: #D23442;
}
.contact__button--trash:active .contact__icon::before {
  color: #FFFFFF;
}

body * {
  scrollbar-width: thin;
}

.page {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  min-width: 320px;
}
.page::-webkit-scrollbar {
  width: 0;
}

.custom-progress {
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  order: 4;
  overflow: hidden;
  white-space: nowrap;
}
.aside-panel--collapsed .custom-progress {
  display: none;
}
.custom-progress__track {
  height: 8px;
  width: 100%;
  background-color: #F0F8FF;
  border-radius: 8px;
  overflow-x: hidden;
}
.custom-progress__bar {
  display: block;
  background-color: #0080D8;
  height: 100%;
  width: 3%;
  border-radius: 8px 0 0 8px;
}
.custom-progress__size {
  font-family: "Manrope";
  font-size: 12px;
  line-height: 24px 500;
  font-weight: normal;
  font-style: normal;
  white-space: nowrap;
  margin-left: 8px;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(32, 40, 64, 0.35);
  z-index: 100;
  pointer-events: auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.top-panel__button .icon {
  display: block;
  height: 32px;
  width: 32px;
}
.top-panel__button .icon::before {
  font-size: 32px;
  line-height: 32px;
}

.value {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  font-style: normal;
}

.accordion {
  position: relative;
  margin-top: 24px;
  width: 100%;
}
.accordion__button {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  background-color: #F0F8FF;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 16px 24px 16px 16px;
  width: 100%;
}
.accordion__button::before {
  position: absolute;
  top: 10px;
  right: 3px;
  transform: rotate(-180deg);
  font-size: 32px;
  line-height: 32px;
  transition: 0.2s ease-in-out;
}
.accordion__button.collapsed::before {
  transform: rotate(0);
}
.accordion__wrapper {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  padding: 16px;
}
.accordion__link {
  color: inherit;
  text-decoration: underline;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(32, 40, 64, 0.35);
  z-index: 10000;
}
.loader__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border: 5px solid #FFFFFF;
  border-bottom-color: #0080D8;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.copied {
  font-family: "Manrope";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  position: absolute;
  top: 50%;
  transform: translateX(-10%) translateY(-50%);
  right: 100%;
  display: block;
  font-size: 0.75em;
  padding: 4px 8px;
  color: #FFFFFF;
  background-color: #0080D8;
  border-radius: 8px;
  opacity: 0;
  will-change: opacity, transform;
  width: max-content;
  animation: showcopied 1.5s ease;
}

@media (min-width: 1200px) {
  .new-mail__form .cover {
    border-radius: 16px;
  }
}
/*# sourceMappingURL=styles.css.map */
