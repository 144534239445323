:root {
    --light-color: #FFFFFF;
    --dark-color: #000000;

    --primary-100: #F3F4F6;
    --primary-200: #E5E7EB;
    --primary-300: #D2D6DB;
    --primary-400: #9DA4AE;
    --primary-500: #6C737F;
    --primary-600: #4D5761;
    --primary-700: #384250;
    --primary-800: #1F2A37;
    --primary-900: #111927;
    
    --brand-25: #F0F8FF;
    --brand-50: #E6F2FB;
    --brand-100: #CCE6F7;
    --brand-200: #99CCEF;
    --brand-300: #66B3E8;
    --brand-400: #3399E0;
    --brand-500: #0080D8;
    --brand-600: #006AB3;
    --brand-700: #005895;
    --brand-800: #00497C;
    --brand-900: #003D67;

    --error-100: #FEE7DA;
    --error-200: #FEC8B6;
    --error-300: #FCA391;
    --error-400: #F98075;
    --error-500: #F54848;
    --error-600: #D23442;
    --error-700: #B0243C;
    --error-800: #8E1635;
    --error-900: #750D31;
    
    --warning-100: #FEF7D2;
    --warning-200: #FEEEA5;
    --warning-300: #FCE178;
    --warning-400: #FAD456;
    --warning-500: #F8C020;
    --warning-600: #D59E17;
    --warning-700: #B27F10;
    --warning-800: #8F620A;
    --warning-900: #774D06;

    --success-100: #E4FBE4;
    --success-200: #CBF8CF;
    --success-300: #AAEAB7;
    --success-400: #8DD6A3;
    --success-500: #66BB88;
    --success-600: #4AA075;
    --success-700: #338665;
    --success-800: #206C55;
    --success-900: #13594B;

    --shadow: 0px 4px 16px 0px rgba(0, 43, 70, 0.10);
}