@keyframes smooth-appearance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes smooth-disappearance {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes collapse {
    0% {
        width: 331px;
    }

    33% {
        width: 248px;
    }

    66% {
        width: 165px;
    }

    100% {
        width: 90px;
    }
}

@keyframes expand {
    0% {
        width: 90px;
    }

    33% {
        width: 165px;
    }

    66% {
        width: 248px;
    }

    100% {
        width: 331px;
    }
}

@keyframes appearance-animation {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes disappearance-animation {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

@mixin move-down($value) {
    @keyframes move-down-animation {
        0% {
            transform: translateY(0);
        }
    
        100% {
            transform: translateY($value);
        }
    }
    
    animation: move-down-animation 0.5s ease-in-out forwards;
}

@mixin move-up($value) {
    @keyframes move-up-animation {
        0% {
            transform: translateY($value);
        }
    
        100% {
            transform: translateY(0);
        }
    }
    
    animation: move-up-animation 0.5s ease-in-out forwards;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

@keyframes showcopied {
    0% {
        opacity: 0;
        transform: translateX(0) translateY(-50%);
    }
    70% {
        opacity: 1;
        transform: translateX(-10%) translateY(-50%);
    }
    100% {
        opacity: 0;
    }
}