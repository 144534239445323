a.custom-link {
    display: inline-flex;
    align-items: center;
    transition: color 0.2s, opacity 0.2s ease-in-out;
    width: auto;
    text-decoration: none;
    cursor: pointer;
    background: none;
    border: none;

    &--default {
        color: var(--brand-300);

        &:active {
            color: var(--brand-500);
            opacity: 0.5;
        }
    }

    &:active {
        text-decoration: none;
    }

    &:focus-within {
        outline: none;
    }
}

@media(min-width: $lg) {
    a.custom-link {    
        &:hover {
            text-decoration: none;
        }

        &--default {
            &:hover {
                color: var(--brand-400);
            }

            &:active {
                color: var(--brand-500);
                opacity: 0.5;
            }
        }
    }
}