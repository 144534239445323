
.color {
    &-icon {
        &-contrast {
            &-light {
                color: var(--light-color);
            }
    
            &-shadow {
                color: var(--primary-900)
            }
        }

        &-primary {
            color: var(--primary-700)
        }

        &-secondary {
            color: var(--primary-500);
        }

        &-tertiary {
            color: var(--primary-400);
        }

        &-brand {
            color: var(--brand-500);
        }

        &-success {
            color: var(--success-500);
        }

        &-error {
            color: var(--error-500);
        }
    
        &-accent {
            color: var(--accent-500);
        }

        &-quinary {
            color: var(--primary-200);
        }
    
        &-quaternary {
            color: var(--primary-300);
        }

        &-background-tertiary {
            color: var(--primary-200)
        }

        &-default {
            color: var(--primary-150);
        }
    }

    &-text {
        &-contrast {
            &-shadow {
                color: var(--primary-900);
            }
            
            &-light {
                color: var(--light-color);
            }
        }

        &-primary {
            color: var(--primary-700);
        }
    
        &-secondary {
            color: var(--primary-500);
        }
    
        &-tertiary {
            color: var(--primary-400);
        }

        &-error {
            color: var(--error-500);
        }

        &-quaternary {
            color: var(--primary-300);
        }
    }

    &-bg {
        &-brand {
            background-color: var(--brand-50);
        }

        &-primary {
            background-color: var(--light-color);
        }

        &-shadow {
            background-color: var(--primary-800);
        }

        &-gradient {
            background: var(--primary-900) url("/images/about-content-bg-figure-1.webp") no-repeat 100%;
            background-position: 25% 50%;
            background-size: cover;
        }
    }
}

.text {
    &-footnote {
        &-regular {
            @include footnote-regular;
        }
    
        &-md-regular {
            @media (min-width: $md) {
                @include footnote-regular;
            }
        }

        &-semibold {
            @include footnote-semibold;
        }
    }

    &-body {
        &-regular {
            @include body-regular;
        }

        &-sm-regular {
            @media (min-width: $sm) {
                @include body-regular;
            }
        }
    
        &-semibold {
            @include body-semibold;
        }

        &-sm-semibold {
            @media (min-width: $sm) {
                @include body-semibold;
            }
        }
    
        &-bold {
            @include body-bold;
        }
    }

    &-body1 {
        &-bold {
            @include body1-bold;
        }

        &-sm-bold {
            @media (min-width: $sm) {
                @include body1-bold;
            }
        }
    }

    &-body2 {
        &-regular {
            @include body2-regular;
        }

        &-lg-regular {
            @media (min-width: $lg) {
                @include body2-regular;
            }
        }

        &-xl-regular {
            @media (min-width: $xl) {
                @include body2-regular;
            }
        }

        &-medium {
            @include body2-medium;
        }
    }

    &-title {
        &-semibold {
            @include title-semibold;
        }

        &-sm-semibold {
            @media (min-width: $sm) {
                @include title-semibold;
            }
        }

        &-bold {
            @include title-bold;
        }

        &-sm-bold {
            @media (min-width: $sm) {
                @include title-bold;
            }
        }
    
        &-md-bold {
            @media (min-width: $md) {
                @include title-bold;
            }
        }
    }

    &-headline {
        &-bold {
            @include headline-bold;
        }

        &-sm-bold {
            @media (min-width: $sm) {
                @include headline-bold;
            }
        }
    
        &-md-bold {
            @media (min-width: $md) {
                @include headline-bold;
            }
        }
    
        &-xl-bold {
            @media (min-width: $xl) {
                @include headline-bold;
            }
        }
    }

    &-headline1 {
        &-bold {
            @include headline1-bold;
        }

        &-sm-bold {
            @media (min-width: $sm) {
                @include headline1-bold;
            }
        }
    
        &-md-bold {
            @media (min-width: $md) {
                @include headline1-bold;
            }
        }
    
        &-xl-bold {
            @media (min-width: $xl) {
                @include headline1-bold;
            }
        }
    }

    &-headline2 {
        &-semibold {
            @include headline2-semibold;
        }

        &-md-semibold {
            @media (min-width: $md) {
                @include headline2-semibold;
            }
        }

        &-lg-semibold {
            @media (min-width: $lg) {
                @include headline2-semibold;
            }
        }

        &-bold {
            @include headline2-bold;
        }

        &-sm-bold {
            @media (min-width: $sm) {
                @include headline2-bold;
            }
        }
    
        &-md-bold {
            @media (min-width: $md) {
                @include headline2-bold;
            }
        }
    
        &-xl-bold {
            @media (min-width: $xl) {
                @include headline2-bold;
            }
        }
    }

    &-display {
        &-bold {
            @include display-bold;
        }

        &-lg-bold {
            @media (min-width: $lg) {
                @include display-bold;
            }
        }
    }

    &-display1 {
        &-bold {
            @include display1-bold;
        }
    }
}

.icon {
    box-sizing: content-box;
    flex-shrink: 0;
}
