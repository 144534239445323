.ck.ck-editor {
    display: flex;
    flex-direction: column;
    height: 100% !important;
}
.ck.ck-editor__main {
    flex-grow: 1;
}
.ck.ck-content {
    height: 100%;
}
.ck-editor__editable[role="textbox"] {
    max-height: none !important;
}
.ck-editor__editable[role="textbox"],
.textarea_class {
    min-height: 200px !important;
    font-family: "Manrope";
    font-size: 14px;
    line-height: 144%;
    font-weight: 400;
    font-style: normal;

    max-height: 300px;
    overflow: auto;
}

.ck-content ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.ck-content ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
