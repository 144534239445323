@mixin font($font-size, $line-height, $font-weight: normal, $font-style: normal) {
    font-family: "Manrope";
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    font-style: $font-style;
}

@mixin scroll-bar-1 {
    &::-webkit-scrollbar {
        width: 8px;
        background-color: $color-8;
        border-radius: 0;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-2;
        border-radius: 0;
    }   
}

@mixin scroll-bar-2 {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-200);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: $transparent;
        border-radius: 8px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        border-radius: 8px;
        
    }

    &::-webkit-scrollbar-thumb {
        background-color: $transparent;
        border-radius: 8px;
    }

    &:hover::-webkit-scrollbar-thumb  {
        background-color: var(--primary-200);
    }
}

@mixin pseudo-element($display: block, $position: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $position;
}

@mixin button($color, $background-color, $width: max-content) {
    padding: 14px;
    white-space: normal;
    border-radius: 8px;
    border: none;
    color: $color;
    background-color: $background-color;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    width: $width;

    &:focus {
        outline: none;
    }
}

@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
}

@mixin visually-visible {
    // width: auto;
    // height: auto;
    margin: auto;
    overflow: visible;
    clip: auto;
    clip-path: none;
}

@mixin custom-scrollbar {
    // scrollbar-width: thin;
    // scrollbar-color: var(--primary-200);
    
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 8px;
        background-color: var(--primary-200);
    }
}

@mixin footnote-regular {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

@mixin footnote-semibold {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

@mixin body-regular {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.64px;
}

@mixin body-semibold {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

@mixin body-bold {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

@mixin body1-bold {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.64px;
}

@mixin body2-regular {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.36px;
}

@mixin body2-medium {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.36px;
}

@mixin title-semibold {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

@mixin title-bold {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

@mixin headline-bold {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.64px;
}

@mixin headline1-bold {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}

@mixin headline2-semibold {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.48px;
}

@mixin headline2-bold {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

@mixin display-bold {
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.92px;
}

@mixin display1-bold {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
}

@mixin hidden-scrollbar {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

