
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/mixins.scss";
          @import "@/assets/scss/global/variables.scss";
          @import "@/assets/scss/global/variables2.scss";
          @import "@/assets/scss/global/variables3.scss";
          @import "@/assets/scss/global/keyframes.scss";
        


@import "inline-elements.scss";
@import "button.scss";
@import "link.scss";
@import "../mail/ck-editor.scss";

body {
    font-family: "Manrope", sans-serif;
}

.main {
    overflow-y: auto;
    @include scroll-bar-2;
}

.custom {
    &-container {
        max-width: 1440px;

        &-header {
            max-width: 475px;

            @media(min-width: $md) {
                max-width: 460px;
            }

            @media(min-width: $lg) {
                max-width: 479px;
            }

            @media(min-width: $xl) {
                max-width: 463px;
            }

            @media(min-width: $xxl) {
                max-width: 506px;
            }
        }

        &-main {
            @media(min-width: $lg) {
                max-width: 385px;
            }

            @media(min-width: $xl) {
                max-width: 608px;
            }

            @media(min-width: $xxl) {
                max-width: 712px;
            }
        }

        &-figure {
            &-bg {
                background-image: url("/images/about-content-bg-figure-2.svg"),
                                  url("/images/about-content-bg-figure-3.svg");
                background-position: -134px 7%, 107% 18%;
                background-size: 292px 413px, 181px 400px;
                background-repeat: no-repeat;

                @media(min-width: $sm) {
                    background-position: -159px 5%, 102% 17%;
                    background-size: 360px 867px, 236px 476px;
                }

                @media(min-width: $md) {
                    background-size: 471px 827px, 344px 853px;
                    background-position: -71% 8%, 103% 28%;
                }

                @media(min-width: $lg) {
                    background-size: 849px 1224px, 645px 924px;
                    background-position: -223% 8%, 136% 29%;
                }

                @media(min-width: $xl) {
                    background-size: 680px 797px, 645px 924px;
                    background-position: -62% 8%, 137% 30%;
                }

                @media(min-width: $xxl) {
                    background-size: 802px 1297px, 645px 924px;
                    background-position: -356px 8%, 116% 24%;
                }

                @media(min-width: 2000px) {
                    background-position: -356px 8%, 106% 24%;
                }

                @media(min-width: 3700px) {
                    background-position: -356px 8%, 104% 24%;
                }

                @media(min-width: 4900px) {
                    background-size: 1002px 1297px, 1645px 1224px;
                    background-position: -11% 8%, 116% 5%;
                }
            }

            &-mask {
                outline: 2px solid var(--light-color);
                background-color: var(--primary-900);
                background-image: url("/images/about-content-bg-figure-4.svg");
                background-position: 63% -1px;
                background-size: 105%;
                background-repeat: no-repeat;
            }
        }

        &-shadow {
            box-shadow: var(--shadow);
        }

        &-security {
            @media(min-width: $md) {
                max-width: calc(100% - 279px);
            }

            @media(min-width: $lg) {
                max-width: calc(100% - 454px);
            }
        }

        &-basis {
            &-md {
                @media(min-width: $md) {
                    width: 48.3%;
                }
            }

            &-lg {
                @media(min-width: $lg) {
                    width: 33%;
                }
            }
        }

        &-image {
            height: 240px;
        }

        &-faq {
            width: 100%;
            max-width: 944px;

            @media(min-width: $xl) {
                width: 944px; 
            }
        }
    }

    &-title {
        max-width: 415px;

        @media(min-width: $md) {
            max-width: none;
        }
    }

    &-text {
        &-headline {
            &-xl-bold {
                @media(min-width: $xl) {
                    @include headline-bold;
                }
            }
        }

        &-headline1 {
            &-sm-bold {
                @media(min-width: $sm) {
                    @include headline1-bold;
                }
            }
        }
        
        &-display1 {
            &-xl-bold {
                @media (min-width: $xl) {
                    @include display1-bold;
                }
            }
        }
    }

    &-image {
        &-header {
            left: calc(50% + 17px);
            transform: translateX(-50%);
            min-width: 365px;
            min-height: 206px;
            width: 100%;
            margin-top: 36px;

            @media(min-width: $sm) {
                left: calc(50% + 40px);
                min-width: 607px;
                min-height: 342px;
                margin-top: 38px;
            }

            @media(min-width: $md) {
                left: calc(50% + 120px);
                min-width: 637px;
                min-height: 359px;
                margin-top: -80px;
            }

            @media(min-width: $lg) {
                left: calc(50% + 120px);
                min-width: 749px;
                min-height: 422px;
                margin-top: -125px;
            }

            @media(min-width: $xl) {
                left: 33%;
                min-width: 883px;
                min-height: 497px;
                margin-top: 0;
            }

            @media(min-width: $xxl) {
                left: 40%;
            }
        }

        &-main {
            max-width: 520px;
        }

        &-security {
            max-width: 270px;

            @media(min-width: $lg) {
                position: absolute;
                max-width: 420px;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }
        }
        
        &-files {
            left: 52px;
            width: 257px;
            height: 176px;

            @media(min-width: $sm) {
                top: -8px;
                left: 14px;
            }
        }

        &-memo {
            width: 184px;
            height: 184px;

            @media(min-width: $sm) {
                top: -20px;
                right: 8px;
            }

            @media(min-width: $md) {
                width: 209px;
                height: 209px;
            }

            @media(min-width: $lg) {
                width: 180px;
                height: 180px;
                top: -11px;
                right: 0;
            }
        }

        &-notes {
            width: 157px;
            height: 157px;

            @media(min-width: $sm) {
                left: -53px;
            }

            @media(min-width: $md) {
                width: 186px;
                height: 186px;
                left: auto;
                right: 50%;
                bottom: 24px;
                transform: translateX(50%);
            }

            @media(min-width: $lg) {
                width: 162px;
                height: 162px;
                left: 0;
                right: auto;
                bottom: auto;
                transform: none;
            }
        }
    }

    &-accordion {
        &-item {
            transition: color 0.15s, background-color 0.15s ease-in-out;
            cursor: pointer;

            &__text {
                color: var(--light-color);
                transition: color 0.15s ease-in-out;
            }
    
            .icon {
                transition: transform 0.15s ease-in-out;
            }
    
            &:not(&.collapsed) .icon {
                transform: rotate(-180deg);
            }

            &:active {
                .custom-accordion-item {
                    &__text {
                        color: var(--brand-500);
                    }
                }
            }

            @media (min-width: $xl) {
                &:hover,
                &:active {
                    .custom-accordion-item {
                        &__text {
                            color: var(--brand-500);
                        }
                    }
                }
            }
        }
    }
}

.round {
    &-12 {
        border-radius: 12px;
    }

    &-16 {
        border-radius: 16px;
    }

    &-20 {
        border-radius: 20px;
    }

    &-28 {
        border-radius: 28px;
    }

    &-sm-28 {
        @media(min-width: $sm) {
            border-radius: 28px;
        }
    }
}

.gutter {
    &-row {
        &-1 {
            row-gap: 6px;
        }
    }
}

.webmoney-header {
    .n7g-adaptive .n7g-mlfs .n7g-mmw {
        height: calc(100vh - 137px);
    }
}

.footer {
    width: 100%;

    .n9g {
        width: 100%;

        &-adaptive {
            .n9g-ctr {
                max-width: none;

                @media(min-width: $md) {
                    padding: 0 32px;
                }

                @media(min-width: $xl) {
                    padding: 0 40px;
                }
            }
        }
    }
}

