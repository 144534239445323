.button {
    transition: color 0.15s, background-color 0.15s, border 0.15s ease-in-out;

    .button {
        &__text {
            transition: color 0.15s, background-color 0.15s, border 0.15s ease-in-out;
        }
    }

    &:focus-visible {
        box-shadow: 0 0 0 3px var(--brand-200);
    }

    &--light {
        background-color: var(--brand-25) ;
        z-index: 1;

        .button {
            &__text {
                color: var(--brand-500) ;
            }
        }

        &:active {
            background-color: var(--light-color);
        }
    }

    &--stroke {
        background-color: transparent;
        border: 1px solid var(--primary-700);

        .button {
            &__text {
                color: var(--light-color) ;
            }
        }

        &:active {
            border: 1px solid var(--brand-400);

            .button {
                &__text {
                    color: var(--brand-400);
                }
            }
        }
    }
}

@media(min-width: $lg) {
    .button {
        &--light {
            &:hover,
            &:active {
                background-color: var(--light-color);
            }
        }

        &--stroke {
            &:hover,
            &:active {
                border: 1px solid var(--brand-400);
    
                .button {
                    &__text {
                        color: var(--brand-400);
                    }
                }
            }
        }
    }
}