@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-ExtraBold.woff2") format("woff2"), url("/fonts/Manrope-ExtraBold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-Light.woff2") format("woff2"), url("/fonts/Manrope-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-Regular.woff2") format("woff2"), url("/fonts/Manrope-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: icons;
	font-display: swap;
	src: url("/fonts/icons.woff2") format("woff2"), url("/fonts/icons.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-SemiBold.woff2") format("woff2"), url("/fonts/Manrope-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-ExtraLight.woff2") format("woff2"), url("/fonts/Manrope-ExtraLight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-Bold.woff2") format("woff2"), url("/fonts/Manrope-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: Manrope;
	font-display: swap;
	src: url("/fonts/Manrope-Medium.woff2") format("woff2"), url("/fonts/Manrope-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

