//resolutions
$mobile: 320px;
$tablet: 768px;
// $laptop: 1200px;
$desktop: 1200px;

$xs: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

//colors
$color-1: #FFFFFF;
$color-2: #0080D8;
$color-3: #C4C4C4;
$color-4: #101828;
$color-5: #EEEEEE;
$color-6: #808086;
$color-7: #282B2E;
$color-8: #F0F8FF;
$color-9: #000000;
$color-10: #B8C0D0;

$color-11: #0066AD;
$color-12: #1F1F1F;
$color-13: #3399E0;
$color-14: #242424;
$color-15: #EE6464;
$color-16: #66BB88;
$color-17: #7452DA;
$color-18: #F5F5F5;
$color-19: #CCCCCC;
$color-20: #E0E0E0;
$color-21: #F54848;
$color-21-transparent: rgba(245, 72, 72, 0.24);
$color-22: #D3D8E2;
$color-23: #FDECEC;
$color-24: #C7D5D4;
$color-25: #EE6464;
$color-26: #121212;
$color-27: #C43A3A;
$color-28: #F76D6D;
$color-29: #FF8844;
$color-30: #FF6464;
$color-31: #FDDADA;
$color-32: #202840;
$color-33: #228866;
$color-34: #A8BB4F;
$color-35: #7A3A3A;
$color-36: #F8D880;
$color-37: #8866EE;
$color-38: #44BB88;
$color-39: #E5E7EB;
$color-40: #D2D6DB;
$color-41: #384250;
$color-42: #F3F4F6;
$color-43: #6C737F;
$color-44: #FEE7DA;
$color-45: #D23442;
$color-46: #B0243C;
$color-47: #99CCEF;
$color-48: #338665;
$color-49: #f3f4f6;
$color-50: #4D5761;
$color-51: #BABCBF;
$color-52: #f9fafb;
$color-53: #F8C020;
$color-54: #FEF7D2;
$color-55: #fbfbfb;
$color-56: #036bb3;
$color-57: #c6c9cf;
$color-58: #E6F2FB;

$brand: $color-56;
$preloader-bg: $color-55;
$error-bg: rgba(196, 58, 58, 0.4);
$success: $color-38;
$error: $color-21;
$background-color: $color-39;
$disabled-block: rgba(32, 40, 64, 0.35);
$transparent: transparent;
